<template>
  <app-dialog
      v-model="isDialogVisible"
      title="Attention"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
  >

    <div class="dialog-text-content">
      <div>
        You are switching to
        <strong>{{ projectLimitationProp.name }}</strong>
        with a limitation of min {{ projectLimitationProp.limit[0] }} projects
        and max {{ projectLimitationProp.limit[1] }} projects.
      </div>

      <div class="mt-4">
        Project limit of {{ projectLimitationProp.limit[0] }} projects will be set.
        If you want to change it, you may do it later via the edit button.
      </div>

      <div class="mt-4">
        Do you want to continue ?
      </div>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              @click="confirmClick"
              :loading="loading"
              :disabled="loading"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>


  </app-dialog>
</template>


<script>
import AppDialog from '@/components/app/AppDialog.vue'
import OrganizationsService from '@/services/organizations.service'

import { mapGetters } from 'vuex'


export default {
  name: 'OrganizationTabProjectLimitationDialogConfirmPlanChange',

  components: {
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    projectLimitationProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      organization: 'organization/getOrganization',
    }),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.$emit('noPlanChange')
      this.isDialogVisible = false
    },

    async confirmClick () {
      try {
        this.loading = true
        await OrganizationsService.changeOrganizationProjectLimit({
          id: this.organization.id,
          project_limit: this.projectLimitationProp.limit[0]
        })

        await this.$store.dispatch(
            'organization/updateOrganizationProjectLimit',
            this.projectLimitationProp.limit[0]
        )
        this.$emit('setPreSelectedLimitPlan')
      } catch (e) {
        this.$emit('noPlanChange')
      }
      finally {
        this.loading = false
        this.isDialogVisible = false
      }
    }
  }


}
</script>



<style scoped lang="scss">

</style>