import VueCookies from 'vue-cookies'
import AuthService from '@/services/auth.service'

const state = () => ({
  user: null
})

const getters = {
  isAuthenticated () {
    let cookie = VueCookies.get('current_user')

    return cookie != null
  },

  getCurrentUser (state) {
    return state.user
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },

  logout (state) {
    state.user = null
  },

  signOut (state) {
    state.user = null
    VueCookies.remove('current_user')
  }
}

const actions = {
  async signOut (vuexContext) {
    vuexContext.commit('signOut')
  },

  async login (vuexContext, data) {
    try {
      const response = await AuthService.login(data)

      if (data.isRememberMe) {
        VueCookies.set('current_user', response.access_token, '1Y', null, null, false, 'Lax')
        vuexContext.commit('setUser', response.user)
        return
      }

      VueCookies.set('current_user', response.access_token, 0, null, null, false, 'Lax')
      vuexContext.commit('setUser', response.user)

    } catch (error) {
      vuexContext.dispatch('snackbar/showSnackbarMessage', {
        message: error,
        duration: 8000,
        mode: 'fail'
      })
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
