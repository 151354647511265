<template>

  <div class="product-dialog-edit-create-feature">
    <app-dialog
      v-model="isDialogVisible"
      :title="dialogTitle"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div>
        <v-text-field
          id="name-field"
          v-model="featureName"
          :error-messages="nameErrors"
          placeholder="Feature name"
          clearable
          label="Feature name"
          required
          @blur="$v.$touch()"
          @input="$v.featureName.$touch()"
        />


        <v-text-field
          id="feature-key-field"
          v-model="featureKey"
          :error-messages="featureKeyErrors"
          placeholder="Feature key"
          label="Feature key"
          required
          @blur="$v.$touch()"
          @input="$v.featureKey.$touch()"

        >
          <template #append>
            <div class="d-flex mr-10">
              <v-tooltip
                color="primary"
                class="text-center"
                nudge-top="-10"
                top
                dark flat
              >
              <span>
                Generate uuid4
              </span>

                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      text
                      plain
                      color="primary"
                      id="generate-feature-key-btn"
                      icon
                      small
                      @click="generateUuid"
                    >
                      <app-svg
                        class="mr-2"
                        color="primary"
                        src="@/assets/img/icons/generate-new.svg"
                        size="28"
                      />
                    </v-btn>
                  </div>
                </template>
              </v-tooltip>


              <v-tooltip
                color="primary"
                class="text-center"
                nudge-top="-10"
                top
                dark flat
              >
              <span>
                Copy to clipboard
              </span>

                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      class="entity-fields-edit-btn"
                      id="copy-feature-key-btn"
                      text
                      plain
                      color="primary"
                      icon
                      small
                      @click="copyToClipBoard"
                    >
                      <app-svg
                        color="primary"
                        src="@/assets/img/icons/copy.svg"
                        size="28"
                      />
                    </v-btn>
                  </div>
                </template>
              </v-tooltip>

            </div>

          </template>
        </v-text-field>
      </div>

      <div class="text--secondary text-small">
        Please note, that Unity is tightly coupled with the Feature key.
        If you decide to change the Feature key, you must consider it with Unity.
        Feature key must be uuid4. They must be all the same for all environments.
      </div>

      <template #actions>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
            >
              Cancel
            </v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              :disabled="$v.$anyError || confirmDisabled || !!isAlreadyExistingFeatureKey"
              :loading="loading"
              @click.native="confirmClick"
            >
            <span class="text-capitalize">
              Confirm
            </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </app-dialog>

    <product-dialog-confirm-feature-key-change
      v-if="isDialogConfirmChangeFeatureKeyVisible"
      v-model="isDialogConfirmChangeFeatureKeyVisible"
      :key="dialogConfirmChangeFeatureKey"
      @editProductFeature="createOrEditProductFeature"
    />
  </div>

</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import AppSvg from '@/components/app/AppSvg'
import ProductDialogConfirmFeatureKeyChange from '@/components/product/dialog/ProductDialogConfirmFeatureKeyChange'

import ProductsService from '@/services/product/products.service'

import { validationMixin } from 'vuelidate'
import { maxLength, required, helpers } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "ProductDialogEditCreateFeature.",

  mixins: [validationMixin],

  components: {
    AppDialog,
    AppSvg,
    ProductDialogConfirmFeatureKeyChange
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    featureProp: {
      type: Object,
      default: () => {
        return {}
      }
    },

    featuresProp: {
      type: Array,
      required: true
    },

    action: {
      type: String,
      default: 'create'
    },
  },

  data () {
    return {
      featureName: this.featureProp ?  this.featureProp.name : '',
      featureKey: this.featureProp ?  this.featureProp.internal_id : '',
      loading: false,

      isDialogConfirmChangeFeatureKeyVisible: false,
      dialogConfirmChangeFeatureKey: uuidv4()
    }
  },

  validations: {
    featureName: {
      required,
      maxLength: maxLength(255),
      validChars: helpers.regex('valid chars',
        new RegExp(/^[a-zA-Z0-9`~!@#$%^&*()\-_=+[\]{}|;:'",.<>/?\\ ]*$/u, 'u')
      )
    },

    featureKey: {
      required,
      maxLength: maxLength(36),
      validChars: helpers.regex('valid chars',
        new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/u, 'u')
      )
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    confirmDisabled () {
      if (this.action === 'create') {
        return !this.featureKey && !this.featureName
      } else {
       return this.featureName === this.featureProp.name && this.featureKey === this.featureProp.internal_id
      }
    },

    nameErrors () {
      const errors = []
      if (!this.$v.featureName.$dirty) {
        return errors
      }

      !this.$v.featureName.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.featureName.required && errors.push('Must contain at least 1 character')
      !this.$v.featureName.validChars && errors.push('Can contain only latin characters, numbers and symbols')
      return errors
    },

    isAlreadyExistingFeatureKey () {
      const foundItem = this.featuresProp.find(item => item.internal_id === this.featureKey)

      if (this.action === 'create') {
        return foundItem
      } else {
          if (foundItem && foundItem.id === this.featureProp.id ) {
            return false
          }
          return foundItem
      }

    },

    featureKeyErrors () {
      const errors = []
      if (!this.$v.featureKey.$dirty) {
        return errors
      }

      this.isAlreadyExistingFeatureKey && errors.push('This key has been already used in other feature, please generate a new one')
      !this.$v.featureKey.maxLength && errors.push('Must contain maximum 36 characters')
      !this.$v.featureKey.required && errors.push('Must contain 36 characters')
      !this.$v.featureKey.validChars && errors.push(
        'Please enter a valid uuid4, it consists of 32 hexadecimal digits and 4 hyphens. You may checkout ' +
        'the web for uuid4 id generators')
      return errors
    },

    dialogTitle () {
      if (this.action === 'create') {
        return 'Create new Feature'
      }

      return 'Edit Feature'
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

   async createOrEditProductFeature () {
      this.loading = true
      try {
        if (this.action === 'create') {
          await ProductsService.createProductFeature({
            product_id: this.$route.params.id,
            name: this.featureName.trim(),
            internal_id: this.featureKey
          })
        } else {
          await ProductsService.editProductFeature({
            product_id: this.$route.params.id,
            feature_id: this.featureProp.id,
            name: this.featureName.trim(),
            internal_id: this.featureKey
          })
        }
      } catch (e) {
        await this.$error(e)
        throw e
      } finally {
        this.loading = false
      }
      this.$emit('fetchProductFeatures')
      this.hideDialog()
    },

    async confirmClick () {
      if(this.action !== 'create' && this.featureProp.internal_id !== this.featureKey) {
        this.isDialogConfirmChangeFeatureKeyVisible = true
        this.dialogConfirmChangeFeatureKey = uuidv4()
      } else {
        await this.createOrEditProductFeature()
      }
    },

    copyToClipBoard () {
      navigator.clipboard.writeText(this.featureKey)
    },

    generateUuid () {
      this.featureKey = uuidv4()


    }
  }
}
</script>

<style scoped>

</style>