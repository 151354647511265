import Vue from 'vue'
import Vuex from 'vuex'

import snackbar from "@/store/modules/snackbar"
import auth from "@/store/modules/auth"
import product from '@/store/modules/product'
import organization from '@/store/modules/organization'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    product,
    organization
  }
})
