<template>
  <app-dialog
      v-model="isDialogVisible"
      :title="'Attention'"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
      :persistent="true"
  >
    <div>
      You are going to make version <strong>
      {{releaseProp.version}}
    </strong>
      {{releaseProp.mandatory_users ? ' mandatory' : 'not mandatory'}}
    </div>

    <div class="mt-3">
      Are you sure you want continue ?
    </div>


    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              @click="confirmClick"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>

</template>


<script>
import AppDialog from '@/components/app/AppDialog.vue'
export default {
  name: 'ProductDialogReleaseRequired',

  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    releaseProp: {
      type: Object,
      required: true
    }
  },


  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
      this.$emit('hideDialog', this.releaseProp)
    },

    confirmClick () {
      this.$emit('changeRequiredState')
      this.hideDialog()
    }
  }
}
</script>

<style scoped lang="scss">

</style>