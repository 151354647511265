import { BaseClass } from '@/classes/base/BaseClass'

export  class ProductReleasePlugin extends BaseClass {
  constructor (data ={}) {
    super()

    this.id = data.id
    this.active = data.active
    this.created_at = data.created_at
    this.mandatory_users = data.mandatory_users
    this.software_versions = data.software_versions
    this.updated_at = data.updated_at
    this.version = data.version

    this._versionAsInt = parseInt(this.version.replace(/\./g, ''))
  }


  get hasArchicadMac () {
    return !!(this.software_versions && this.software_versions.archicad_macos)
  }

  get hasArchicadWindows () {
    return !!(this.software_versions && this.software_versions.archicad_windows)
  }

  get hasRevitWindows () {
    return !! (this.software_versions && this.software_versions.revit_windows)
  }

  get hasAnyFile () {
    return this.hasArchicadMac || this.hasArchicadWindows || this.hasRevitWindows
  }


}