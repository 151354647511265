<template>
  <div>
    <app-loader-listing v-if="loadingFetchSelectedFeatures"  />

    <div       v-else>
      <!--ADD NEW FEATURE BUTTON-->
      <div>
        <div
          class="big-row-btn-with-icon d-flex align-center pa-5 my-5"
          id="add-new-feature-btn"
          @click="showAddNewFeatureDialog"
        >
          <v-avatar size="60">
            <div class="icon">
              <app-svg
                alt="icon"
                color="white"
                size="32"
                src="~/assets/img/icons/plus.svg"
              />
            </div>
          </v-avatar>

          <div class="section-title ml-5">
            Add new feature
          </div>
        </div>
      </div>

      <!--EACH FEATURE-->
      <div
        v-for="(feature, index) in features"
        :key="`catalogue-${feature.id}-${index}`"
        class="px-2"
      >
        <app-list-item hover>

          <template #title>
            <div class="d-flex align-center">
              <div>{{ feature.name }}</div>
            </div>
          </template>

          <template #actions>
            <div
                class="d-flex"
                id="remove-feature-btn"
                @click="showDeleteFeatureDialog(feature)"
            >
              <v-spacer />
              <v-btn
                class="primary--text"
                height="30px"
                rounded
                text
              >
                <div class="guttered-row">
                  <app-svg
                    class="mr-2"
                    color="primary"
                    src="@/assets/img/icons/delete.svg"
                    width="16"
                  />
                  Remove
                </div>
              </v-btn>
            </div>
          </template>

        </app-list-item>
      </div>
    </div>

    <!--DIALOGS-->
    <organization-dialog-add-new-feature
      v-if="isAddNewFeatureDialogVisible"
      v-model="isAddNewFeatureDialogVisible"
      :key="addNewFeatureDialogKey"
      :product-prop="productProp"
      @fetchOrganizationSelectedProductFeatures="fetchOrganizationSelectedProductFeatures"
    />

    <organization-dialog-delete-feature
      v-if="isDeleteFeatureDialogVisible"
      v-model="isDeleteFeatureDialogVisible"
      :key="deleteFeatureDialogKey"
      :feature-prop="currentFeature"
      @fetchOrganizationSelectedProductFeatures="fetchOrganizationSelectedProductFeatures"
    />

  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg.vue'
import AppListItem from '@/components/app/AppListItem.vue'
import AppLoaderListing from '@/components/app/AppLoaderListing.vue'
import OrganizationDialogAddNewFeature from '@/components/organization/dialog/OrganizationDialogAddNewFeature.vue'
import OrganizationDialogDeleteFeature from '@/components/organization/dialog/OrganizationDialogDeleteFeature.vue'

import OrganizationsService from '@/services/organizations.service'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: "OrganizationTabProductFeaturesContent",

  components: {
    AppSvg,
    AppListItem,
    OrganizationDialogAddNewFeature,
    OrganizationDialogDeleteFeature,
    AppLoaderListing
  },

  props: {
    productProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      features: null,

      loadingFetchSelectedFeatures: false,

      isAddNewFeatureDialogVisible: false,
      addNewFeatureDialogKey: uuidv4(),

      isDeleteFeatureDialogVisible: false,
      deleteFeatureDialogKey: uuidv4(),

      currentFeature: null
    }
  },

  methods: {
    showAddNewFeatureDialog () {
      this.isAddNewFeatureDialogVisible = true
      this.addNewFeatureDialogKey = uuidv4()
    },

    showDeleteFeatureDialog (feature) {
      this.currentFeature = feature
      this.isDeleteFeatureDialogVisible = true
      this.deleteFeatureDialogKey = uuidv4()
    },

    async fetchOrganizationSelectedProductFeatures () {
      this.loadingFetchSelectedFeatures = true
      try {
        this.features = await OrganizationsService.getOrganizationSelectedProductFeatures({
          organizationId: this.$route.params.id,
          productId: this.productProp.id
        })
      } catch (e) {
        await this.$error(e)
        this.$router.replace('/organizations')
      } finally {
        this.loadingFetchSelectedFeatures = false
      }
    }
  },

  async created () {
    await this.fetchOrganizationSelectedProductFeatures()
  },

  destroyed () {
    this.currentFeature = null
  }

}
</script>

<style scoped>

</style>