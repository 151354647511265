import $axios from '@/plugins/axios'
import axios from 'axios'
import store from '@/store'
class FileService {
  getPresignedUrl (data) {
    return $axios.post('/generate-s3-presigned-url', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  uploadFileWithPresignedUrl ({ file, url, onProgress, cancelToken  }) {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
        'Access-Control-Allow-Origin' : '*',
      },
      onUploadProgress: progressEvent => {
        if (onProgress) {
          const progress = (progressEvent.loaded / progressEvent.total) * 100
          onProgress(progress)
        }
      },

      cancelToken: cancelToken?.token
    })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // pass
        } else {
          store.dispatch('snackbar/showSnackbarMessage', {
            message: error.message,
            duration: 4000,
            mode: 'fail'
          })
          throw error
        }
      })
  }
}


export default new FileService()