<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-md-row">

      <organization-edit-left-side-panel-container />

      <!--      TABS-->
      <div class="flex-grow-1 ma-4">
        <v-tabs
          v-model="selectedTab"
          active-class="tab-active"
          class="mb-4"
          hide-slider
          show-arrows
        >
          <v-tab
            key="product-features"
            href="#product-features"
          >
            Product Features
          </v-tab>

          <v-tab
              key="product-limitations"
              href="#product-limitations"
          >
            Project Limitations
          </v-tab>

        </v-tabs>

        <div class="tabs-content-section pa-5">
          <v-tabs-items v-model="selectedTab">
            <v-tab-item
              key="product-features"
              value="product-features"
            >

              <organization-tab-product-features />
            </v-tab-item>

            <v-tab-item
                key="product-limitations"
                value="product-limitations"
            >
              <organization-tab-project-limitations />
            </v-tab-item>

          </v-tabs-items>
        </div>
      </div>

    </v-layout>
  </div>
</template>

<script>
import OrganizationEditLeftSidePanelContainer
  from '@/components/organization/edit/OrganizationEditLeftSidePanelContainer'
import OrganizationTabProductFeatures from '@/components/organization/tab/product-features/OrganizationTabProductFeatures.vue'
import OrganizationTabProjectLimitations
  from '@/components/organization/tab/project-limitations/OrganizationTabProjectLimitations.vue'

export default {
  name: "OrganizationSingleOverview",
  
  components: {
    OrganizationEditLeftSidePanelContainer,
    OrganizationTabProductFeatures,
    OrganizationTabProjectLimitations
  },

  computed: {
    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>