import $axios from '../plugins/axios'

class OrganizationService {
  getAllOrganizations () {
    return $axios.get('/get-organizations')
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createOrganization (organization) {
    return $axios.post('/create-organization', organization)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOrganization (organizationId) {
    return $axios.get(`/get-organization/${organizationId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOrganizationAvailableProductFeatures ({ organizationId, productId }) {
    return $axios.get(`get-organization-available-features/${organizationId}?product_id=${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getOrganizationSelectedProductFeatures ({ organizationId, productId }) {
    return $axios.get(`get-organization-selected-features/${organizationId}?product_id=${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteOrganizationSelectedProductFeature (data) {
    return $axios.post('/delete-organization-selected-feature', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  selectOrganizationProductFeature (data) {
    return $axios.post('/select-organization-feature', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeOrganizationProjectLimit (data) {
    return $axios.post('/change-organization-project-limit', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeOrganizationOwner (data) {
    return $axios.post('/change-organization-owner', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

}

export default new OrganizationService()
