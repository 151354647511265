<template>
  <app-dialog
    v-model="isDialogVisible"
    title="Change Feature Key"
    max-width="800"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      Are you sure that you want to change feature key ?
    </div>

    <div>
      Please note, that Unity is tightly coupled with the Feature key.
      If you decide to change the Feature key, you must consider it with Unity.
      Feature key must be uuid4. They must be all the same for all environments.
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="confirmClick"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
export default {
  name: "ProductDialogConfirmFeatureKeyChange",

  components: {
    AppDialog,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    confirmClick () {
      this.hideDialog()
      this.$emit('editProductFeature')
    }
  }
}
</script>

<style scoped lang="scss">
</style>