<template>
  <app-dialog
      :value="isDialogVisible"
      v-bind="$attrs"
      v-on="$listeners"
      :title="dialogTitle"
      max-width="1500"
      :persistent="true"
  >
    <div>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
              clearable
              required
              label="Version Number"
              class="padding-left"
              v-model="versionNumber"
              :error-messages="versionErrors"
              @blur="$v.$touch"
              @input="$v.versionNumber.$touch()"
              :disabled="modeProp === 'edit'"
          />
        </v-col>
      </v-row>

      <div class="program-wrapper mt-6 px-5 py-6">
        <div class="text-header">
          Archicad
        </div>

        <div class="d-flex align-center mt-6 os-wrapper">
          <div>
            <app-svg
                src="~/assets/img/icons/windows-icon.svg"
                size="24"
            />
          </div>
          <div class="ml-2">
            Windows
          </div>
        </div>

        <v-row>
          <template v-for="index in [0, 1, 2]">
            <v-col :key="`archicad-windows-year-${index}`" cols="12" sm="1">
              <v-text-field
                  required
                  class="padding-left"
                  v-model="release.archicad.windows[index].year"
                  :error-messages="release.archicad.windows[index]._yearErrors"
                  @blur="release.archicad.windows[index].checkYearValidity()"
                  @input="release.archicad.windows[index].checkYearValidity()"
              />
            </v-col>

            <v-col :key="`archicad-windows-file-${index}`" cols="12" sm="3" class="pl-0">
              <v-file-input
                  placeholder="Upload file"
                  hint="Files must be only in format .exe or .msi"
                  prepend-icon=""
                  :clearable="false"
                  messages="Files must be only in format .exe or .msi"
                  class="padding-left"
                  v-model="release.archicad.windows[index].file"
                  :error-messages="fileError(release.archicad.windows[index])"
                  @blur="release.archicad.windows[index].checkFileNotValid()"
                  @input="release.archicad.windows[index].checkFileNotValid()"
              >
                <template #append>
                  <div class="d-flex align-center justify-space-between">
                    <app-svg
                        size="16"
                        src="~/assets/img/icons/mini-upload-file.svg"
                        class="ml-12"
                        color="var(--v-grey-darken1)"
                    />
                  </div>
                </template>

                <template
                    #label
                    v-if="release.archicad.windows[index].storageLocation && !release.archicad.windows[index].file"
                >
                  <div>
                    {{ fileName(release.archicad.windows[index].storageLocation) }}
                  </div>
                </template>

              </v-file-input>
            </v-col>
          </template>
        </v-row>


        <div class="d-flex align-center mt-6 os-wrapper mt-4">
          <div>
            <app-svg
                src="~/assets/img/icons/mac-icon.svg"
                size="24"
            />
          </div>
          <div class="ml-2">
            Mac OS
          </div>
        </div>

        <v-row>
          <template v-for="(index, key) in [0, 1, 2]">
            <v-col cols="12" sm="1" :key="`${key} + archicad mac year`">
              <v-text-field
                  required
                  class="padding-left"
                  v-model="release.archicad.mac[index].year"
                  :error-messages="release.archicad.mac[index]._yearErrors"
                  @blur="release.archicad.mac[index].checkYearValidity()"
                  @input="release.archicad.mac[index].checkYearValidity()"
              />
            </v-col>

            <v-col cols="12" sm="3" class="pl-0" :key="`${key} + archicad mac file`">
              <v-file-input
                  placeholder="Upload file"
                  hint="Files must be only in format .zip or .dmg"
                  prepend-icon=""
                  :clearable="false"
                  messages="Files must be only in format .zip or .dmg"
                  class="padding-left"
                  v-model="release.archicad.mac[index].file"
                  :error-messages="fileError(release.archicad.mac[index])"
                  @blur="release.archicad.mac[index].checkFileNotValid()"
                  @input="release.archicad.mac[index].checkFileNotValid()"
              >
                <template #append>
                  <div class="d-flex align-center justify-space-between">
                    <app-svg
                        size="16"
                        src="~/assets/img/icons/mini-upload-file.svg"
                        class="ml-12"
                        color="var(--v-grey-darken1)"
                    />
                  </div>
                </template>

                <template
                    #label
                    v-if="release.archicad.mac[index].storageLocation && !release.archicad.mac[index].file"
                >
                  <div>
                    {{ fileName(release.archicad.mac[index].storageLocation) }}
                  </div>
                </template>

              </v-file-input>
            </v-col>
          </template>

        </v-row>

      </div>


      <div class="program-wrapper mt-6 px-5 py-6">
        <div class="text-header">
          Revit
        </div>

        <div class="d-flex align-center mt-6 os-wrapper">
          <div>
            <app-svg
                src="~/assets/img/icons/windows-icon.svg"
                size="24"
            />
          </div>
          <div class="ml-2">
            Windows
          </div>
        </div>

        <v-row>
          <template v-for="(index, key) in [0, 1, 2]">
            <v-col cols="12" sm="1" :key="`${key} + revit windows year`">
              <v-text-field
                  required
                  class="padding-left"
                  v-model="release.revit.windows[index].year"
                  :error-messages="release.revit.windows[index]._yearErrors"
                  @blur="release.revit.windows[index].checkYearValidity()"
                  @input="release.revit.windows[index].checkYearValidity()"
              />
            </v-col>

            <v-col cols="12" sm="3" class="pl-0" :key="`${key} + revit windows file`">
              <v-file-input
                  placeholder="Upload file"
                  hint="Files must be only in format .exe"
                  prepend-icon=""
                  :clearable="false"
                  messages="Files must be only in format .exe or .msi"
                  class="padding-left"
                  v-model="release.revit.windows[index].file"
                  :error-messages="fileError(release.revit.windows[index])"
                  @blur="release.revit.windows[index].checkFileNotValid()"
                  @input="release.revit.windows[index].checkFileNotValid()"
              >
                <template #append>
                  <div class="d-flex align-center justify-space-between">
                    <app-svg
                        size="16"
                        src="~/assets/img/icons/mini-upload-file.svg"
                        class="ml-12"
                        color="var(--v-grey-darken1)"
                    />
                  </div>
                </template>

                <template
                    #label
                    v-if="release.revit.windows[index].storageLocation && !release.revit.windows[index].file"
                >
                  <div>
                    {{ fileName(release.revit.windows[index].storageLocation) }}
                  </div>
                </template>

              </v-file-input>
            </v-col>
          </template>

        </v-row>

      </div>

      <div class="d-flex mt-6" v-if="modeProp !== 'edit'">
        <v-checkbox
            class="ma-0 pa-0"
            color="primary"
            @click.prevent=""
            :ripple="false"
            v-model="mandatory"
            :disabled="noFilesToUpload"
        />

        <div>
          Mandatory for users
        </div>
      </div>

    </div>

    <template #actions>
      <v-row justify-sm="end">
        <v-col cols="12" sm="2">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
              :disabled="loading"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="2">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              @click="publish"
              :loading="loading"
              :disabled="isPublishBtnDisabled || loading"
          >
            <span class="text-capitalize">
              Publish
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>


<script>
import AppDialog from '@/components/app/AppDialog.vue'
import AppSvg from '@/components/app/AppSvg.vue'

import { ProductReleasePluginCreator } from '@/classes/product/releases/ProductReleasePluginCreator'

import { versionMixin } from '@/mixins/VersionMixin'
import { releaseMixin } from '@/mixins/ReleaseMixin'

import ProductReleasesPluginService from '@/services/product/product.releases.plugin.service'

export default {
  name: 'ProductDialogAddEditPluginRelease',

  mixins: [versionMixin, releaseMixin],

  components: {AppDialog, AppSvg},

  props: {
    value: {
      default: true
    },

    modeProp: {
      type: String,
      required: true
    },

    releaseProp: {
      type: Object,
      default: () => {
        return null
      }
    }
  },


  data () {
    return {
      versionNumber: this.modeProp === 'edit' ? this.releaseProp.version : null,

      mandatory: false,

      release: {
        archicad: {
          windows: this.createManyNewReleasePlgObjects({ softwareProp: 'archicad_windows', os: 'win' }),
          mac: this.createManyNewReleasePlgObjects({ softwareProp: 'archicad_macos', os: 'mac' })
        },
        revit: {
          windows: this.createManyNewReleasePlgObjects({ softwareProp: 'revit_windows', os: 'win'})
        }
      },

      payload: {
        product_id: this.$route.params.id,
        software_versions: {
          revit_windows: [],
          archicad_windows: [],
          archicad_macos: []
        },
        mandatory: false,
        active: false
      },

      loading: false
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', !!value)
      }
    },

    noFilesToUpload () {
      return !this.hasFileToUpload(this.release.archicad.windows) &&
          !this.hasFileToUpload(this.release.archicad.mac) &&
          !this.hasFileToUpload(this.release.revit.windows)
    },

    allFilesAreValid () {
      return this.release.archicad.windows.every(item => item.checkFileNotValid() === false) &&
          this.release.archicad.mac.every(item => item.checkFileNotValid() === false) &&
          this.release.revit.windows.every(item => item.checkFileNotValid() === false)
    },

    allYearsAreValid () {
      return this.release.archicad.windows.every(item => item._yearErrors === '') &&
          this.release.archicad.mac.every(item => item._yearErrors === '') &&
          this.release.revit.windows.every(item => item._yearErrors === '')
    },

    isPublishBtnDisabled () {
      if (this.modeProp === 'edit') {
        return this.noFilesToUpload || !this.allFilesAreValid || !this.allYearsAreValid
      }
      return !this.versionNumber || this.$v.$anyError || !this.isValidVersion || !this.allFilesAreValid || !this.allYearsAreValid
    },

    dialogTitle () {
      if (this.modeProp === 'edit') {
        return 'Edit Release'
      }
      return 'Add New Release'
    }
  },

  watch: {
    mandatory (value) {
      this.payload.mandatory = value
    },

    noFilesToUpload (value) {
      if (value) {
        this.mandatory = false
      }
    },

    versionNumber: {
      immediate: true,
      handler (value) {
        if (this.modeProp === 'edit') {
          this.payload.version = this.versionNumber
        } else {
          this.payload.version = value
        }
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    fileError (item) {
      if (item && item.checkFileNotValid()) return item.validationErrMsg
      return ''
    },

    createManyNewReleasePlgObjects ({ softwareProp, os }) {
      const result = []
      const isEditMode = this.modeProp === 'edit'
      const maxItems = 3

      for (let i = 0; i < maxItems; i++) {
        result.push(this.createReleasePluginObject({ softwareProp, index: i, isEditMode, os }))
      }
      return result
    },

    createReleasePluginObject ({ softwareProp, index, isEditMode, os }) {
      if (!isEditMode) {
        return new ProductReleasePluginCreator({os})
      }

      const softwareVersion = this.releaseProp.software_versions[softwareProp]

      if (softwareVersion && softwareVersion[index]) {
        return new ProductReleasePluginCreator({
          year: softwareVersion[index].version,
          file: softwareVersion[index].file,
          fileId: softwareVersion[index].file.id,
          storageLocation: softwareVersion[index].file.storage_location,
          os: os
        })
      }

      return new ProductReleasePluginCreator({os})
    },

    hasFileToUpload (dataArr) {
      return dataArr.some(item => !!item.file)
    },

    preparePayload ({dataAry, softwareProp}) {
      if (this.hasFileToUpload(dataAry)) {
        for (const release of dataAry) {
          if (release.file) {
            this.payload.software_versions[`${softwareProp}`].push({
              version: release.year,
              file_id: release.fileId
            })
          }
        }
      }
    },

    async publish () {
      try {
        this.loading = true
        await Promise.all(this.release.archicad.windows.map(item => item.makePresignedUrl()))
        await Promise.all(this.release.archicad.mac.map(item => item.makePresignedUrl()))
        await Promise.all(this.release.revit.windows.map(item => item.makePresignedUrl()))

        await Promise.all(this.release.archicad.windows.map(item => item.uploadFileToPresignedUrl()))
        await Promise.all(this.release.archicad.mac.map(item => item.uploadFileToPresignedUrl()))
        await Promise.all(this.release.revit.windows.map(item => item.uploadFileToPresignedUrl()))

        this.preparePayload({
          dataAry: this.release.archicad.windows,
          softwareProp: 'archicad_windows'
        })

        this.preparePayload({
          dataAry: this.release.archicad.mac,
          softwareProp: 'archicad_macos'
        })

        this.preparePayload({
          dataAry: this.release.revit.windows,
          softwareProp: 'revit_windows'
        })

        if (this.modeProp === 'edit') {
          delete this.payload.mandatory
          delete this.payload.active
          delete this.payload.product_id

          this.payload.release_id = this.releaseProp.id
          await ProductReleasesPluginService.updatePluginRelease(this.payload)
        } else {
          await ProductReleasesPluginService.createPluginRelease(this.payload)
        }

        this.$emit('fetchReleases')
      } finally {
        this.loading = false
        this.hideDialog()
      }
    }
  }
}
</script>


<style scoped lang="scss">
.program-wrapper {
  background-color: var(--v-grey-lighten2);
  border-radius: 6px;
}

.text-header {
  font-size: 20px;
  font-weight: bold;
  color: var(--v-grey-darken2);
}

.os-wrapper {
  color: var(--v-grey-darken2);
}
</style>