<template>
  <div
    :class="{
      'hover': hover || link || hasClickListener,
      'selected': selected,
      'disabled': disabled,
      'link': link || hasClickListener
    }"
    :style="styleOptions"
    class="app-list-item"
    @click.prevent="onClick"
  >
    <span v-if="selected" class="drag-handle" />
    <v-checkbox
      v-if="showCheckbox"
      :disabled="disabled"
      :input-value="selected"
      value
      @change="updateSelected($event)"
      @click.native.stop=""
    />

    <slot />

    <div v-if="hasTitleSlot || hasSubtitleSlot" class="list-item-content">
      <div v-if="hasTitleSlot" class="content-section content-title">
        <slot name="title" />
      </div>
      <div v-if="hasSubtitleSlot" class="content-section content-subtitle">
        <slot name="subtitle" />
      </div>
    </div>

    <div class="list-item-actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppListItem',

  props: {
    hover: Boolean,
    selected: Boolean,
    disabled: Boolean,

    minHeight: {
      type: [String, Number, undefined],
      default: undefined
    },
    link: {
      type: [String, undefined],
      default: undefined
    }
  },

  computed: {
    styleOptions () {
      const result = {}
      if (this.minHeight) {
        result['min-height'] = (this.minHeight << 0) + 'px'
      }
      return result
    },

    showCheckbox () {
      // has selected listener
      if (this.$listeners && this.$listeners['update:selected']) {
        return true
      }

      // check if has selected prop
      return (
        this.$options &&
        this.$options.propsData &&
        Object.keys(this.$options.propsData).includes('selected')
      )
    },

    hasClickListener () {
      return this.$listeners && this.$listeners.click
    },

    hasTitleSlot () {
      return this.$scopedSlots && this.$scopedSlots.title
    },

    hasSubtitleSlot () {
      return this.$scopedSlots && this.$scopedSlots.subtitle
    }
  },

  methods: {
    updateSelected (state) {
      this.$emit('update:selected', state)
    },

    onClick (e) {
      this.$emit('click', e)

      if (this.link) {
        this.$router.replace(this.link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.app-list-item {
  position: relative;
  z-index: 1;
  outline: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  transition: background $secondary-transition;
  color: var(--v-grey-darken2);
  font-family: $body-font-family;
  font-size: 1rem;
  word-break: break-word;

  &.selected {
    background: var(--v-grey-lighten2) !important
  }

  // default children spacing
  & > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  .list-item-actions {
    display: inline-flex;
    align-items: center;
    margin: 0 -5px 0 auto;
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: opacity $secondary-transition,
    transform $secondary-transition,
    visibility $secondary-transition;

    & > * {
      margin-left: 10px;
    }
  }

  .list-item-content {
    display: flex;
    min-height: 54px;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;

    .content-section {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .content-subtitle {
      font-size: 0.75rem;
      color: var(--v-grey-darken1);
    }

    .content-title ~ .content-subtitle {
      margin-top: 5px;
    }
  }

  // dragging
  .drag-no-handle &:before { // drag bg overlay
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: var(--v-grey-darken2);
    opacity: 0.25;
    visibility: hidden;
    transition: opacity $secondary-transition,
    visibility $secondary-transition;
  }

  .drag-handle {
    display: none;
    position: absolute;
    left: 7px;
    opacity: 0.25;
    transform: translateX(-3px);
    transition: opacity $secondary-transition,
    transform $secondary-transition;
  }

  .drag-no-handle & .drag-handle {
    display: block;
  }

  .drag-no-handle.drag-source & .drag-handle,
  .drag-no-handle.drag-in & .drag-handle {
    opacity: 0.25;
    transform: translateX(0px);
  }

  .drag-no-handle.drag-source & {
    z-index: 1;
    box-shadow: 1px 1px 3px 0 rgba(#000, 0.12);

    &:before {
      visibility: visible;
      opacity: 0.05;
    }
  }

  // states
  &.link {
    cursor: pointer;
  }

  &.link,
  &.hover {
    user-select: none;

    .next-checkbox:not(.checked) {
      opacity: 0.25;
      transition: opacity $secondary-transition;
    }
  }

  html:not(.drag-in-progress) &.hover:hover,
  html:not(.drag-in-progress) &.hover:focus-within,
  html:not(.drag-in-progress) &.hover:active {
    background: var(--v-grey-lighten2);

    .next-checkbox {
      opacity: 1;
    }

    .list-item-actions {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.drag-handle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 24px;
  user-select: none;

  &:before,
  &:after {
    content: '';
    width: 1px;
    height: 100%;
    float: left;
    background: var(--v-grey-base);;
  }

  &:after {
    margin-left: 4px;
  }
}

</style>
