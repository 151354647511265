<template>
  <div>
    <app-single-item-edit-left-section
      v-if="organizationProp"
      :entity-item-prop="organizationProp"
      :entity-type-prop="'project'"
      @showEditDescriptionDialog="$emit('showEditDescriptionDialog')"
      @showEditImageDialog="$emit('showEditImageDialog')"
      @showEditNameDialog="$emit('showEditNameDialog')"
    >
      <template #other-edit-fields>

        <div class="d-flex text-regular pb-4">
          <v-hover>
            <template v-slot="{ hover }">
              <div
                class="description-text hover-text-effect"
                style="cursor:pointer;"
                @click="$emit('showEditWebsiteDialog')"
              >
              <strong class="description-text">Website:</strong>
              <span
                v-if="organizationProp.website"
                style="
                 white-space: pre-wrap;
                 word-break: break-word;
                "
              >
                {{ organizationProp.website }}
              </span>

                <span
                  v-else
                  class="entity-fields-text-span"
                >
                Website is empty click here to edit it
              </span>

                <v-btn
                  v-if="true"
                  :class="{ 'element-invisible': !hover }"
                  class="entity-fields-edit-description-btn ml-1"
                  color="primary"
                  height="22"
                  icon
                  small
                  width="22"
                >
                  <app-svg
                    size="14"
                    src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </div>
            </template>

          </v-hover>
        </div>

        <div class="d-flex text-regular pb-4">
          <v-hover>
            <template v-slot="{ hover }">
              <div
                class="description-text hover-text-effect"
                style="cursor:pointer;"
                @click="$emit('showEditSlugDialog')"
              >
              <strong class="description-text">Slug:</strong>
              <span
                v-if="organizationProp.slug"
                style="
                 white-space: pre-wrap;
                 word-break: break-word;
                "
              >
                {{ organizationProp.slug }}
              </span>

                <span
                  v-else
                  class="entity-fields-text-span"
                >
                Slug is empty click here to edit it
              </span>

                <v-btn
                  v-if="true"
                  :class="{ 'element-invisible': !hover }"
                  class="entity-fields-edit-description-btn ml-1"
                  color="primary"
                  height="22"
                  icon
                  small
                  width="22"
                >
                  <app-svg
                    size="14"
                    src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </div>
            </template>

          </v-hover>
        </div>


        <div class="d-flex text-regular pb-4">
          <v-hover>
            <template v-slot="{ hover }">
              <div
                  class="description-text hover-text-effect"
                  style="cursor:pointer;"
                  @click="$emit('showConfirmChangeOwnershipDialog')"
              >
                <strong class="description-text">Owner:</strong>
                <span
                    v-if="organizationProp.owner.name "
                    style="
                 white-space: pre-wrap;
                 word-break: break-word;
                "
                >
                {{ organizationProp.owner.name }}
              </span>

                <span
                    v-else
                    class="entity-fields-text-span"
                >
                Owner is empty click here to edit it
              </span>

                <v-btn
                    v-if="true"
                    :class="{ 'element-invisible': !hover }"
                    class="entity-fields-edit-description-btn ml-1"
                    color="primary"
                    height="22"
                    icon
                    small
                    width="22"
                >
                  <app-svg
                      size="14"
                      src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </div>
            </template>

          </v-hover>
        </div>


        <div class="d-flex text-regular pb-4">
<!--          <v-hover>-->
<!--            <template v-slot="{ hover }">-->
              <div
                class="description-text hover-text-effect"
                style="cursor:pointer;"
                @click="$emit('showEditEmailDialog')"
              >
              <strong class="description-text">Owner's email:</strong>
              <span
                v-if="organizationProp.owner.email"
                style="
                 white-space: pre-wrap;
                 word-break: break-word;
                "
              >
                {{ organizationProp.owner.email }}
              </span>

                <span
                  v-else
                  class="entity-fields-text-span"
                >
                Email is empty
              </span>

<!--                <v-btn-->
<!--                  v-if="true"-->
<!--                  :class="{ 'element-invisible': !hover }"-->
<!--                  class="entity-fields-edit-description-btn ml-1"-->
<!--                  color="primary"-->
<!--                  height="22"-->
<!--                  icon-->
<!--                  small-->
<!--                  width="22"-->
<!--                >-->
<!--                  <app-svg-->
<!--                    size="14"-->
<!--                    src="~/assets/img/icons/edit.svg"-->
<!--                  />-->
<!--                </v-btn>-->
              </div>
<!--            </template>-->

<!--          </v-hover>-->
        </div>

        <div class="d-flex flex-column text-regular" >
          <v-hover v-slot="{ hover }">
              <strong
                class="description-text hover-text-effect"
                style="cursor:pointer;"
                @click="$emit('showAttachProductDialog')"
              >
                Products:

                <v-btn
                  v-if="hover"
                  class="entity-fields-edit-description-btn ml-1"
                  color="primary"
                  height="22"
                  icon
                  small
                  width="22"
                >
                  <app-svg
                    size="14"
                    src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </strong>
          </v-hover>

          <div class="chips-container pt-4">
            <div v-for="product in organizationProp.products" :key="product.id">
              <v-chip
                x-small
              >
                {{ product.name }}
              </v-chip>
            </div>
          </div>
        </div>
      </template>
    </app-single-item-edit-left-section>
  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'
import AppSingleItemEditLeftSection from '@/components/app/AppSingleItemEditLeftSection'

export default {
  name: "OrganizationEditLeftSidePanel",

  components: {
    AppSvg,
    AppSingleItemEditLeftSection
  },

  props: {
    organizationProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      selectedProducts: [],
      menuProps: {
        bottom: true,
        contentClass: 'select-menu-bottom',
        closeOnContentClick: true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chips-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

</style>