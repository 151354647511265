<template>
  <div class="heading-wrapper">
    <v-layout>
      <h2>{{ titleProp }}</h2>

      <v-spacer />

      <app-button-back
        v-if="backLinkProp.length"
        :to="backLinkProp"
      />
    </v-layout>
  </div>
</template>

<script>
import AppButtonBack from '@/components/app/AppButtonBack'

export default {
  name: 'AppSubpageHeader',
  components: { AppButtonBack },

  props: {
    titleProp: {
      type: String,
      required: true
    },
    backLinkProp: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
