import $axios from '@/plugins/axios'

class ProductReleasesPluginService {

  getProductPluginReleases (data) {
    return $axios.post('/get-plugin-releases', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeActiveStateProductPluginRelease (data) {
    return $axios.post('/change-active-state-plugin-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeRequiredStateProductPluginRelease (data) {
    return $axios.post('/change-required-state-plugin-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createPluginRelease (data) {
    return $axios.post('/create-new-plugin-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deletePluginRelease (data) {
    return $axios.post('/delete-plugin-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  updatePluginRelease (data) {
    return $axios.post('/update-plugin-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ProductReleasesPluginService()