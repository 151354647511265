import $axios from '../../plugins/axios'

class ProductService {
  getAllProducts () {
    return $axios.get('/get-products')
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProduct (productId) {
    return $axios.get (`/get-product/${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createProduct (data) {
    return $axios.post('/create-product', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  getProductFeatures (productId) {
    return $axios.get (`/get-product-features/${productId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  createProductFeature (data) {
    return $axios.post('/create-product-feature', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  editProductFeature (data) {
    return $axios.post('/edit-product-features', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteProductFeature (featureId) {
    return $axios.post (`delete-product-features/${featureId}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  enableDisableProductFeature (data) {
    return $axios.post('/toggle-product-features', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new ProductService()
