<template>
  <div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <v-tabs
            v-model="selectedTab"
            hide-slider
            show-arrows
            active-class="simple-tab-active"
            background-color="transparent"
        >
          <v-tab key="configurator" href="#configurator" class="simple-tab">
            Configurator
          </v-tab>

          <v-tab key="plugin" href="#plugin" class="simple-tab">
            Plugin
          </v-tab>

          <v-tab key="url" href="#url" class="simple-tab" :disabled="true">
            URL
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item key="configurator" value="configurator">
            <product-tab-releases-versions-table
              v-if="selectedTab === 'configurator'"
              :add-edit-dialog-prop="dialogAddEditConfRelease"
              :releases-prop="releases"
              :loading-prop="loading"
              :delete-action-prop="productReleasesConfiguratorService.deleteConfRelease"
              @changeStatus="changeReleaseStatus"
              @showRequiredDialog="showRequiredDialog"
              @changeReleaseRequired="changeReleaseRequiredState"
              @fetchReleases="fetchReleases"
            />
          </v-tab-item>

          <v-tab-item key="plugin" value="plugin">
            <product-tab-releases-versions-table
              v-if="selectedTab === 'plugin' && releases"
              :add-edit-dialog-prop="dialogAddEditPluginRelease"
              :releases-prop="releases"
              :loading-prop="loading"
              :delete-action-prop="productReleasesPluginService.deletePluginRelease"
              @changeStatus="changeReleaseStatus"
              @showRequiredDialog="showRequiredDialog"
              @changeReleaseRequired="changeReleaseRequiredState"
              @fetchReleases="fetchReleases"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>

    <v-divider class="mt-5 mb-5" />

    <product-dialog-release-required
      v-if="showDialogRequiredConfirm"
      v-model="showDialogRequiredConfirm"
      :key="keyDialogRequiredConfirm"
      :release-prop=currentRelease
      @changeRequiredState="changeReleaseRequiredState"
      @hideDialog="(release) => release.mandatory_users = !release.mandatory_users"
    />
  </div>
</template>

<script>
import ProductTabReleasesVersionsTable from '@/components/product/tab/ProductTabReleasesVersionsTable.vue'
import ProductDialogAddEditConfiguratorRelease
  from '@/components/product/dialog/releases/configurator/ProductDialogAddEditConfiguratorRelease.vue'
import ProductDialogAddEditPluginRelease
  from '@/components/product/dialog/releases/plugin/ProductDialogAddEditPluginRelease.vue'
import ProductDialogReleaseRequired from '@/components/product/dialog/releases/ProductDialogReleaseRequired.vue'


import productReleasesConfigurator from '@/services/product/product.releases.configurator.service'
import productReleasesPlugin from '@/services/product/product.releases.plugin.service'


import { v4 as uuidv4 } from 'uuid'
import productReleasesConfiguratorService from '@/services/product/product.releases.configurator.service'
import productReleasesPluginService from '@/services/product/product.releases.plugin.service'

import { ProductReleasePlugin } from '@/classes/product/releases/ProductReleasePlugin'
import { ProductReleaseConfigurator } from '@/classes/product/releases/ProductReleaseConfiurator'

export default {
  name: "ProductTabReleases",

  components: {
    ProductTabReleasesVersionsTable,
    ProductDialogReleaseRequired
  },


  data () {
    return {
      selectedTab: 'configurator',
      keyReleases: uuidv4(),

      loading: true,

      releases: [],

      showDialogRequiredConfirm: false,
      keyDialogRequiredConfirm: uuidv4(),

      fetchReleasesPayload: { product_id: this.$route.params.id },

      currentRelease: null,

      actionsMap: [
        {
          name: 'configurator',
          fetchFunction: this.fetchConfiguratorReleases,
          statusFunc: this.changeRelConfStatus,
          requiredStateFunc: this.changeRelConfRequiredState
        },

        {
          name: 'plugin',
          fetchFunction: this.fetchPluginReleases,
          statusFunc: this.changeRelPluginStatus,
          requiredStateFunc: this.changeRelPluginRequiredState
        }
      ]
    }
  },

  watch: {
    selectedTab:  {
      immediate: true,
      async handler () {
        await this.fetchReleases()
      }

    }
  },

  computed: {
    dialogAddEditConfRelease () {
      return ProductDialogAddEditConfiguratorRelease
    },

    dialogAddEditPluginRelease () {
      return ProductDialogAddEditPluginRelease
    },

    currentActions () {
      return this.actionsMap.find(item => item.name === this.selectedTab)
    },

    productReleasesConfiguratorService () {
      return productReleasesConfiguratorService
    },

    productReleasesPluginService () {
      return productReleasesPluginService
    },

    currentReleaseWithActiveStatus () {
      return this.releases.find(item => item.active)
    }
  },

  methods: {
    async fetchConfiguratorReleases () {
      try {
        this.loading = true
        const releasesResponse = await productReleasesConfigurator.getProductConfiguratorReleases(this.fetchReleasesPayload)

        this.releases = ProductReleaseConfigurator.createList(releasesResponse)
        this.releases = this.releases.sort((a, b) => b._versionAsInt - a._versionAsInt)

      } catch (e) {
        this.$router.push('/dashboard')
      } finally {
        this.loading = false
      }
    },

    async fetchPluginReleases () {
      try {
        this.loading = true
        const releasesResponse = await productReleasesPlugin.getProductPluginReleases(this.fetchReleasesPayload)

        this.releases = ProductReleasePlugin.createList(releasesResponse)
        this.releases = this.releases.sort((a, b) => b._versionAsInt - a._versionAsInt)

      } catch (e) {
        this.$router.push('/dashboard')
      } finally {
        this.loading = false
      }
    },

    async fetchReleases () {
      await this.currentActions.fetchFunction()
    },

    async changeRelConfStatus ({ releaseId, state }) {
      try {
        this.loading = true
        const payload = { release_id: releaseId, status: state }
        await productReleasesConfigurator.changeActiveStateProductConfRelease(payload)
      } catch (e) {
        this.$router.push('/dashboard')
      } finally {
        this.loading = false
      }
    },

    async changeRelPluginStatus ({ releaseId, state }) {
      try {
        this.loading = true
        const payload = { release_id: releaseId, status: state }
        await productReleasesPluginService.changeActiveStateProductPluginRelease(payload)
      } catch (e) {
        this.$router.push('/dashboard')
      } finally {
        this.loading = false
      }
    },

    async changeRelConfRequiredState ({ releaseId, state } ) {
      try {
        this.loading = true
        const payload = { release_id: releaseId, status: state }
        await productReleasesConfigurator.changeRequiredStateProductConfRelease(payload)
      } catch (e) {
        this.$router.push('/dashboard')
      } finally {
        this.loading = false
      }
    },

    async changeRelPluginRequiredState ({ releaseId, state } ) {
      try {
        this.loading = true
        const payload = { release_id: releaseId, status: state }
        await productReleasesPluginService.changeRequiredStateProductPluginRelease(payload)
      } catch (e) {
        this.$router.push('/dashboard')
      } finally {
        this.loading = false
      }
    },

    showRequiredDialog (release) {
      this.currentRelease = release
      this.showDialogRequiredConfirm = true
      this.keyDialogRequiredConfirm = uuidv4()
    },

    async changeReleaseRequiredState () {
      const payload = { releaseId: this.currentRelease.id, state: this.currentRelease.mandatory_users }
      await this.currentActions.requiredStateFunc(payload)

      if (this.currentReleaseWithActiveStatus && this.currentReleaseWithActiveStatus._versionAsInt < this.currentRelease._versionAsInt) {
        await this.currentActions.statusFunc({ releaseId: this.currentRelease.id, state: true })
      }

      await this.fetchReleases()
    },

    async changeReleaseStatus ({ releaseId, state }) {
      await this.currentActions.statusFunc({ releaseId, state })
      await this.fetchReleases()
    }
  },

  destroyed () {
    this.currentRelease = null
  }
}
</script>

<style lang="scss" scoped>
</style>