<template>
  <app-dialog
    v-model="isDialogVisible"
    :title="'Remove'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <span>Do you really want to remove version
        <strong>{{releaseProp.version}}</strong> ?
      </span>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            block
            color="primary"
            raised
            rounded
            x-large
            @click="deleteRelease"
            :loading="loading"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog.vue'

import { releaseMixin } from '@/mixins/ReleaseMixin'
export default {
  name: "ProductDialogDeleteRelease",
  components: { AppDialog },
  mixins: [releaseMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    releaseProp: {
      type: Object,
      required: true
    },

    deleteActionProp: {
      type: Function,
      default: () => {
        return null
      }
    }
  },

  data () {
    return {
      loading: false,
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async deleteRelease () {
      try {
        this.loading = true
        await this.deleteActionProp({ release_id: this.releaseProp.id })
        this.$emit('fetchReleases')
      } finally {
        this.loading = false
        this.hideDialog()
      }

    }
  }

}
</script>

<style lang="scss" scoped>
</style>