<template>
  <div class="product-dialog-confirm-enable-disable-feature">
    <app-dialog
      v-model="isDialogVisible"
      :title="title"
      max-width="800"
      v-bind="$attrs"
      v-on="$listeners"
    >

      <div>
        Removing or Disabling the feature in this page will affect the feature in the organisation page.
      </div>

      <div>
        Are you sure that, you want to
        <i>
          <strong>
            "{{ featureProp.enabled ? 'disable' : 'enable' }}"
          </strong>
        </i>
        <strong>
          {{ featureProp.name }}
        </strong> ?
      </div>

      <template #actions>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
            >
              Cancel
            </v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              @click.native="confirmClick"
            >
            <span class="text-capitalize">
              Confirm
            </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>

    </app-dialog>
  </div>

</template>

<script>
import AppDialog from '@/components/app/AppDialog'


export default {
  name: "ProductDialogConfirmEnableDisableFeature",
  components: {
    AppDialog,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    featureProp: {
      value: {
        type: Object,
        required: true
      }
    },
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    title () {
      return  `Confirm ${ this.featureProp.enabled ? 'disabling' : 'enabling'} feature`
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    confirmClick () {
      this.hideDialog()
      this.$emit('enableDisableFeature', this.featureProp)
    }
  }


}
</script>

<style scoped>

</style>