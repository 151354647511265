import OrganizationsService from '@/services/organizations.service'

import { OrganizationClass } from '@/classes/organization/OrganizationClass'

import Vue from 'vue'

const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED",
  DIALOG_SUBMIT_LOADING: "DIALOG_SUBMIT_LOADING",
  DIALOG_SUBMIT_ERROR: "DIALOG_SUBMIT_ERROR"
}


const state = {
  currentState: actionStates.INITIAL,
  organization: null,
}

const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getOrganization (state) {
    return state.organization
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setOrganization (state, organization) {
    state.organization = organization
  },

  setOrganizationProjectLimit (state, projectLimit) {
    Vue.set(state.organization, 'project_limit', projectLimit)
  }
}

const actions = {
  async fetchOrganization (vuexContext, organizationId) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      const organizationResponse = await OrganizationsService.getOrganization(organizationId)
      const organization = new OrganizationClass(organizationResponse)

      vuexContext.commit('setOrganization', organization)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  updateOrganizationProjectLimit (vuexContext, projectLimit) {
    vuexContext.commit('setOrganizationProjectLimit', projectLimit)
  }
}

export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}