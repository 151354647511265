import ProductsService from '@/services/product/products.service'

import { ProductClass } from '@/classes/product/ProductClass'
import { ProductFeatureClass } from '@/classes/product/ProductFeatureClass'


const actionStates = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERRORED: "ERRORED",
  NOT_FOUND: "NOT_FOUND",
  DATA_LOADED: "DATA_LOADED",
  DIALOG_SUBMIT_LOADING: "DIALOG_SUBMIT_LOADING",
  DIALOG_SUBMIT_ERROR: "DIALOG_SUBMIT_ERROR"
}


const state = {
  currentState: actionStates.INITIAL,
  product: null,
  productFeatures: null
}
const getters = {
  getCurrentState (state) {
    return state.currentState
  },

  getProduct (state) {
    return state.product
  },

  getProductFeatures (state) {
    return state.productFeatures
  }
}

const mutations = {
  setActionState (state, actionState) {
    state.currentState = actionState
  },

  setProduct (state, product) {
    state.product = product
  },

  setProductFeatures (state, productFeatures) {
    state.productFeatures = productFeatures
  }
}

const actions = {
  async fetchProduct (vuexContext, productId) {
    vuexContext.commit('setActionState', actionStates.LOADING)

    try {
      const productResponse = await ProductsService.getProduct(productId)
      const product = new ProductClass(productResponse)
      vuexContext.commit('setProduct', product)

      await vuexContext.dispatch('fetchProductFeatures', productId)
      vuexContext.commit('setActionState', actionStates.DATA_LOADED)
    } catch (e) {
      if (e && e.response && e.response.status === 404) {
        vuexContext.commit('setActionState', actionStates.NOT_FOUND)
      } else {
        vuexContext.commit('setActionState', actionStates.ERRORED)
      }
      throw e
    }
  },

  async fetchProductFeatures (vuexContext, productId) {
    try {
      const productFeaturesResponse = await ProductsService.getProductFeatures(productId)
      const productFeatures = ProductFeatureClass.createList(productFeaturesResponse)
      vuexContext.commit('setProductFeatures', productFeatures)
    } catch (e) {
      vuexContext.commit('setActionState', actionStates.ERRORED)
      throw e
    }
  }
}


export { actionStates }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}