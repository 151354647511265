<template>
  <app-dialog
    v-model="isDialogVisible"
    :persistent="true"
    :title="title"
    max-width="480"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <v-text-field
        id="name-field"
        v-model="organization.name"
        :error-messages="nameErrors"
        :placeholder="'Name'"
        clearable
        label="Name"
        required
        @blur="$v.organization.name.$touch()"
      />

      <v-text-field
        id="slug-field"
        :error-messages="slugErrors"
        :placeholder="'Slug'"
        :value="organization.slug"
        clearable
        label="Slug"
        required
        @blur="$v.organization.slug.$touch()"
        @input="convertStringToSlug($event)"
      />

      <v-file-input
        id="file-field"
        v-model="organization.logo"
        :error-messages="logoErrors"
        accept=".png, .jpg, .jpeg"
        chips
        label="Logo"
        multiple
        @blur="$v.organization.logo.$touch()"
      />

      <v-text-field
        id="website-field"
        v-model="organization.website"
        :error-messages="websiteErrors"
        :placeholder="'Website'"
        clearable
        label="Website"
        required
        @blur="$v.organization.website.$touch()"
      />

      <v-text-field
        id="owner-email-field"
        v-model="organization.ownerEmail"
        :error-messages="ownerEmailErrors"
        :placeholder="'Owner Email'"
        clearable
        label="Owner Email"
        required
        @blur="$v.organization.ownerEmail.$touch()"
      />

      <v-select
        v-model="organization.products"
        :error-messages="productsErrors"
        :item-text="'name'"
        :item-value="'id'"
        :items="productsItems"
        :menu-props="{ bottom: true, offsetY: true }"
        chips
        label="Select products"
        multiple
        @blur="$v.organization.products.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            :disabled="$v.$anyError || isSelectedImageHaveValidFormat === false"
            :loading="isLoadingProp"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="createOrganization"
          >
            <span class="text-capitalize">
              {{ action }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import { email, maxLength, required } from 'vuelidate/lib/validators'
import AppDialog from '@/components/app/AppDialog'
import ProductService from '@/services/product/products.service'

export default {
  name: 'OrganizationDialogCreateEdit',
  components: { AppDialog },

  props: {
    title: {
      type: String,
      required: true
    },

    placeholderProp: {
      type: String,
      default: 'Add organization name...'
    },

    action: {
      type: String,
      default: 'CREATE'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      default: false,
      required: true
    },

    organizationProp: {
      type: Object,
      default () {
        return {
          name: null,
          slug: null,
          logo: null,
          website: null,
          ownerEmail: null,
          products: []
        }
      }
    }
  },

  validations: {
    organization: {
      name: {
        required,
        maxLength: maxLength(50)
      },

      slug: {
        required
      },

      logo: {
        required
      },

      website: {
        required
      },

      ownerEmail: {
        required,
        email
      },

      products: {
        required
      }
    }
  },

  data () {
    return {
      organization: this.organizationProp,
      isButtonLoading: false,
      productsItems: [],
      validFormats: [
        'jpeg',
        'jpg',
        'png'
      ],
      isSelectedImageHaveValidFormat: true
    }
  },

  methods: {
    hideDialog () {
      this.resetOrganization()
      this.isDialogVisible = false
    },

    resetOrganization () {
      this.organization = {
        name: null,
        slug: null,
        logo: null,
        website: null,
        ownerEmail: null,
        products: []
      }
    },

    async prepareFormDataForOrganization () {
      let bodyFormData = new FormData()

      bodyFormData.set('name', this.organization.name)
      bodyFormData.set('slug', this.organization.slug)
      bodyFormData.set('logo', this.organization.logo[0])
      bodyFormData.set('website', this.organization.website)
      bodyFormData.set('owner_email', this.organization.ownerEmail)
      bodyFormData.set('products', JSON.stringify(this.organization.products))

      return bodyFormData
    },

    async createOrganization () {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      const organizationFormData = await this.prepareFormDataForOrganization()
      this.$emit('createOrganization', organizationFormData)
    },

    async getAllProducts () {
      this.productsItems = await ProductService.getAllProducts()
    },

    convertStringToSlug (value) {
      this.organization.slug = new String(value
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/--+/g, '-')
      )
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    nameErrors () {
      const errors = []
      if (!this.$v.organization.name.$dirty) {
        return errors
      }

      !this.$v.organization.name.maxLength && errors.push('Name must be at most 50 characters long')
      !this.$v.organization.name.required && errors.push('Name is required.')
      return errors
    },

    slugErrors () {
      const errors = []
      if (!this.$v.organization.slug.$dirty) {
        return errors
      }

      !this.$v.organization.slug.required && errors.push('Slug is required.')
      return errors
    },

    logoErrors () {
      const errors = []

      !this.isSelectedImageHaveValidFormat && errors.push('Please choose valid format: .png .jpeg .jpg')

      if (!this.$v.organization.logo.$dirty) {
        return errors
      }

      !this.$v.organization.logo.required && errors.push('Logo is required.')
      return errors
    },

    websiteErrors () {
      const errors = []
      if (!this.$v.organization.website.$dirty) {
        return errors
      }

      !this.$v.organization.website.required && errors.push('Website is required.')
      return errors
    },

    ownerEmailErrors () {
      const errors = []
      if (!this.$v.organization.ownerEmail.$dirty) {
        return errors
      }

      !this.$v.organization.ownerEmail.required && errors.push('Owner email is required.')
      !this.$v.organization.ownerEmail.email && errors.push('Owner email must be valid e-mail.')
      return errors
    },

    productsErrors () {
      const errors = []
      if (!this.$v.organization.products.$dirty) {
        return errors
      }

      !this.$v.organization.products.required && errors.push('Product is required.')
      return errors
    }
  },

  watch: {
    'organization.logo' (newValue) {
      if (newValue[0]) {
        const fileExtension = this.organization.logo[0].name.substring(this.organization.logo[0].name.lastIndexOf('.') + 1, this.organization.logo[0].name.length)
        this.isSelectedImageHaveValidFormat = this.validFormats.some(validFormat => validFormat === fileExtension)
      }
    }
  },

  mounted () {
    this.getAllProducts()
  }
}
</script>

<style scoped>

</style>
