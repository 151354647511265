<template>
  <app-dialog
    v-model="isDialogVisible"
    :title="'Remove Feature'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      Are you sure you want to remove <strong>{{ featureProp.name }}</strong> ?
    </div>

    <template #actions>
      <v-row class="d-flex justify-end">
        <v-col cols="3">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="3">
          <v-btn
            id="submit-button"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="removeFeature"
            :loading="loading"
            :disabled="loading"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

import OrganizationsService from '@/services/organizations.service'

export default {
  name: "OrganizationDialogDeleteFeature",

  components: {
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    featureProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false,
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async  removeFeature () {
      try {
        this.loading = true
        await OrganizationsService.deleteOrganizationSelectedProductFeature({
          'feature_id': this.featureProp.id,
          'organization_id': this.$route.params.id
        })
      } catch (e) {
        await this.$error(e)
      } finally {
        this.$emit('fetchOrganizationSelectedProductFeatures')
        this.hideDialog()
      }

    }
  }
}
</script>

<style scoped>

</style>