<template>
  <div>
    <app-subpage-header
      :back-link-prop="'/organizations'"
      :title-prop="'Organizations'"
    />

    <app-loader-content
      v-if="currentState === actionStateConstants.LOADING"
    />

    <organization-single-overview
      v-else-if="currentState !== actionStateConstants.LOADING"
    />
  </div>
</template>

<script>
import AppSubpageHeader from '@/components/app/AppSubpageHeader'
import AppLoaderContent from '@/components/app/AppLoaderContent'
import OrganizationSingleOverview from '@/components/organization/OrganizationSingleOverview'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/product'

export default {
  name: "OrganizationSingleView",
  components: {
    AppSubpageHeader,
    OrganizationSingleOverview,
    AppLoaderContent
  },

  data () {
    return {
      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        currentState: 'organization/getCurrentState'
      }
    )
  },

  methods: {
    async fetchOrganization () {
      try {
        await this.$store.dispatch('organization/fetchOrganization', this.$route.params.id)
      } catch (error) {
        this.$error(error)
        await this.$router.replace('/organizations')
      }
    }
  },

  async created () {
    await this.fetchOrganization()
  }

}
</script>

<style scoped>

</style>