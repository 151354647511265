var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-dialog',_vm._g(_vm._b({attrs:{"title":_vm.title,"max-width":"480"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"id":"close-dialog-button","block":"","color":"primary","outlined":"","raised":"","rounded":"","x-large":""},on:{"click":function($event){$event.preventDefault();return _vm.hideDialog.apply(null, arguments)}}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"id":"submit-button","disabled":_vm.isCreateBtnDisabled,"loading":_vm.loading,"block":"","color":"primary","raised":"","rounded":"","x-large":""},nativeOn:{"click":function($event){return _vm.createClick.apply(null, arguments)}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.action)+" ")])])],1)],1)]},proxy:true}]),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},'app-dialog',_vm.$attrs,false),_vm.$listeners),[_c('div',[_c('v-text-field',{attrs:{"id":"name-field","error-messages":_vm.nameErrors,"placeholder":_vm.placeholderProp,"clearable":"","label":"Name","required":""},on:{"blur":function($event){return _vm.$v.productName.$touch()},"input":function($event){return _vm.$v.productName.$touch()}},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}}),_c('v-text-field',{attrs:{"clearable":"","required":"","label":"Slug","error-messages":_vm.filedErrors({
          fieldName: 'slug', fieldNameUi: 'Slug', maxLength: 30,
          beProp: 'slug', value: _vm.slug
          })},on:{"blur":function($event){return _vm.$v.slug.$touch()},"input":function($event){return _vm.$v.slug.$touch()}},model:{value:(_vm.slug),callback:function ($$v) {_vm.slug=$$v},expression:"slug"}}),_c('v-text-field',{attrs:{"clearable":"","required":"","label":"Domain","error-messages":_vm.filedErrors({
          fieldName: 'domain', fieldNameUi: 'Domain', maxLength: 255,
          beProp: 'domain', value: _vm.domain
          })},on:{"blur":function($event){return _vm.$v.domain.$touch()},"input":function($event){return _vm.$v.domain.$touch()}},model:{value:(_vm.domain),callback:function ($$v) {_vm.domain=$$v},expression:"domain"}}),_c('v-text-field',{attrs:{"required":"","clearable":"","label":"Api Domain","error-messages":_vm.filedErrors({
          fieldName: 'apiDomain', fieldNameUi: 'Api Domain', maxLength: 255,
          beProp: 'api_domain', value: _vm.apiDomain
          })},on:{"blur":function($event){return _vm.$v.apiDomain.$touch()},"input":function($event){return _vm.$v.apiDomain.$touch()}},model:{value:(_vm.apiDomain),callback:function ($$v) {_vm.apiDomain=$$v},expression:"apiDomain"}}),_c('v-text-field',{attrs:{"required":"","clearable":"","label":"SQS Queue","error-messages":_vm.filedErrors({
          fieldName: 'sqsQueue', fieldNameUi: 'SQS Queue', maxLength: 255,
          beProp: 'sqsQueue', value: _vm.sqsQueue
        })},on:{"blur":function($event){return _vm.$v.sqsQueue.$touch()},"input":function($event){return _vm.$v.sqsQueue.$touch()}},model:{value:(_vm.sqsQueue),callback:function ($$v) {_vm.sqsQueue=$$v},expression:"sqsQueue"}}),_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"color":"primary","ripple":false,"label":"Launcher"},on:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.isLauncher),callback:function ($$v) {_vm.isLauncher=$$v},expression:"isLauncher"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }