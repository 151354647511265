import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: '#1A8833',
          lighten1: '#90de93',
          lighten2: '#E0FFE8',
          darken1: '#05691C',
          darken2: '#264f2a'
        },
        anchor: '#65c66a',
        // ---
        info: '#3B86FF',
        warning: '#FFB755',
        error: '#F0445F',
        success: '#3CC480',
        grey: {
          base: '#969696',
          lighten1: '#C3C3C3',
          lighten2: '#F0F0F0',
          lighten3: '#F9F9F9',
          lighten4: '#FAFAFA',
          darken1: '#6F6F6F',
          darken2: '#444444'
        },
        'tag-important': {
          base: '#F0445F'
        },
        'tag-cad': {
          base: '#C9ECDB'
        },
        'tag-document': {
          base: '#FBE9D0'
        }
      }
    }
  }
})
