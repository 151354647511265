<template>
  <div>

    <app-loader-listing v-if="loading || !productFeatures" />

    <div v-else>
      <!--    CREATE NEW FEATURE BTN-->
      <div>
        <div
          class="big-row-btn-with-icon d-flex align-center pa-5 my-5 create-new-feature-btn"
          @click="showCreateFeatureDialog"
        >
          <v-avatar size="60">
            <div class="icon">
              <app-svg
                alt="icon"
                color="white"
                size="32"
                src="~/assets/img/icons/plus.svg"
              />
            </div>
          </v-avatar>

          <div class="section-title ml-5">
            Create new feature
          </div>
        </div>
      </div>
      <!--    EACH FEATURE-->
      <div v-if="productFeatures && productFeatures.length">
        <div
          v-for="(feature, index) in productFeatures"
          :key="`catalogue-${feature.id}-${index}`"
          class="px-2"
        >

          <app-list-item hover>

            <template #title>
              <div class="d-flex flex-row align-center">
                <v-hover >
                  <template v-slot="{ hover }">

                    <div class="d-flex align-center">
                      <div>{{ feature.name }}</div>
                      <div class="d-flex align-center  ml-2 mr-8 edit-feature-btn" @click="showEditFeatureDialog(feature)">
                        <v-btn
                          v-if="hover"
                          class="entity-fields-edit-btn plain-button"
                          text
                          plain
                          color="primary"
                          icon
                          small
                        >
                          <app-svg
                            size="16"
                            src="~/assets/img/icons/edit.svg"
                          />
                        </v-btn>
                      </div>
                    </div>


                  </template>
                </v-hover>

                <div class="ml-4 enable-disable-feature-switch"
                     @click="showEnableDisableFeatureDialog(feature)"
                >
                  <v-switch
                    readonly
                    dense
                    :ripple="false"

                    :inset="false"
                    :input-value="feature.enabled"
                    :loading="feature.loadingEnableDisable"
                  >
                  </v-switch>
                </div>

              </div>
            </template>

            <template #actions>
              <div class="d-flex delete-feature-btn" @click="showDeleteFeatureDialog(feature)">
                <v-spacer />
                <v-btn
                  class="primary--text"
                  height="30px"
                  rounded
                  text
                >
                  <div class="guttered-row">
                    <app-svg
                      class="mr-2"
                      color="primary"
                      src="@/assets/img/icons/delete.svg"
                      width="16"
                    />
                    Remove
                  </div>
                </v-btn>
              </div>
            </template>

          </app-list-item>

        </div>
      </div>
    </div>

    <product-dialog-edit-create-feature
      v-model="isCreateFeatureDialogVisible"
      v-if="isCreateFeatureDialogVisible"
      :key="createFeatureDialogKey"
      @fetchProductFeatures="fetchProductFeatures"
      :features-prop="productFeatures"
    />

    <product-dialog-edit-create-feature
      v-model="isEditFeatureDialogVisible"
      v-if="isEditFeatureDialogVisible"
      :key="editFeatureDialogKey"
      action="edit"
      :feature-prop="currentFeature"
      @fetchProductFeatures="fetchProductFeatures"
      :features-prop="productFeatures"
    />

    <product-dialog-delete-feature
      v-model="isDeleteFeatureDialogVisible"
      v-if="isDeleteFeatureDialogVisible"
      :key="deleteFeatureDialogKey"
      :feature-prop="currentFeature"
      @fetchProductFeatures="fetchProductFeatures"
      class="delete-feature-dialog"
    />

    <product-dialog-confirm-enable-disable-feature
      v-model="isConfirmEnableDisableFeatureDialogVisible"
      v-if="isConfirmEnableDisableFeatureDialogVisible"
      :key="confirmEnableDisableFeatureDialogKey"
      :feature-prop="currentFeature"
      @enableDisableFeature="enableDisableFeature"
      class="enable-disable-feature-dialog"
    />

  </div>
</template>

<script>
import AppSvg from '@/components/app/AppSvg'
import AppListItem from '@/components/app/AppListItem'
import AppLoaderListing from '@/components/app/AppLoaderListing'

import ProductDialogEditCreateFeature from '@/components/product/dialog/ProductDialogEditCreateFeature'
import ProductDialogDeleteFeature from '@/components/product/dialog/ProductDialogDeleteFeature'
import ProductDialogConfirmEnableDisableFeature
  from '@/components/product/dialog/ProductDialogConfirmEnableDisableFeature'


import ProductsService from '@/services/product/products.service'

import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'


export default {
  name: "ProductTabFeatures",
  components: {
    ProductDialogConfirmEnableDisableFeature,
    AppSvg,
    AppListItem,
    ProductDialogEditCreateFeature,
    ProductDialogDeleteFeature,
    AppLoaderListing,
  },

  data () {
    return {

      isCreateFeatureDialogVisible: false,
      createFeatureDialogKey: uuidv4(),

      isDeleteFeatureDialogVisible: false,
      deleteFeatureDialogKey: uuidv4(),

      isEditFeatureDialogVisible: false,
      editFeatureDialogKey: uuidv4(),

      isConfirmEnableDisableFeatureDialogVisible: false,
      confirmEnableDisableFeatureDialogKey:  uuidv4(),

      currentFeature: null,

      imageUrl: null,
      file: null,

      loading: false,
      loadingEnableDis: false
    }
  },

  computed: {
    ...mapGetters (
      {
        productFeatures: 'product/getProductFeatures'
      }
    )
  },

  methods: {
    showCreateFeatureDialog () {
      this.isCreateFeatureDialogVisible = true
      this.createFeatureDialogKey = uuidv4()
    },

    showDeleteFeatureDialog (feature) {
      this.currentFeature = feature
      this.deleteFeatureDialogKey = uuidv4()
      this.isDeleteFeatureDialogVisible = true
    },

    showEditFeatureDialog (feature) {
      this.currentFeature = feature
      this.editFeatureDialogKey = uuidv4()
      this.isEditFeatureDialogVisible = true
    },

    showEnableDisableFeatureDialog (feature) {
      this.currentFeature = feature
      this.isConfirmEnableDisableFeatureDialogVisible = true
      this.confirmEnableDisableFeatureDialogKey = uuidv4()
    },

    async fetchProductFeatures () {
      try {
        this.loading = true
        await this.$store.dispatch('product/fetchProductFeatures', this.$route.params.id)
      } catch (e) {
        await this.$error(e)
      } finally {
        this.loading = false
      }
    },

    async enableDisableFeature (feature) {
      try {
        feature.loadingEnableDisable = true

        await ProductsService.enableDisableProductFeature({
          feature_id: feature.id,
          enabled: !feature.enabled
        })

        feature.enabled = !feature.enabled
      } catch (e) {
        await this.$error(e)
      } finally {
        feature.loadingEnableDisable = false

      }
    }
  },

  destroyed () {
    this.currentFeature = null
  }
}
</script>

<style lang="scss" scoped>

.image-container {
  height: 400px;
  border: 1px solid #ccc;
  padding: 10px;
  max-width: 700px;
}
</style>