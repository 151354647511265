<template>
  <app-dialog
    v-model="isDialogVisible"
    :title="'Remove'"
    max-width="500"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <span>Do you really want to remove <strong>{{ featureProp.name }}</strong> ?</span>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            id="submit-button"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="confirmClick"
            :loading="loading"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'

import ProductsService from '@/services/product/products.service'

export default {
  name: "ProductDialogDeleteFeature",

  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    featureProp: {
      type: Object,
      required: true
    },
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async confirmClick () {
      this.loading = true
      try {
        await ProductsService.deleteProductFeature(this.featureProp.id)
      } catch (e) {
        await this.$error(e)
      } finally {
        this.$emit('fetchProductFeatures')
        this.hideDialog()
      }

    }
  }
}
</script>

<style lang="scss" scoped>
</style>