<template>
  <div v-if="organizationCurrentState === actionStateConstants.DATA_LOADED">
    <v-radio-group
        v-for="limitPlan in limitPlans"
        :key="limitPlan.id"
        class="limitation-line pl-2"
        @change="showProjectLimitPlanConformationDialog"
        v-model="preSelectedLimitPlan"

    >
      <v-radio
          :ripple="false"
          :value="limitPlan"
      >
        <template v-slot:label>
          <div class="d-flex flex-column pl-2 pt-3 pb-0 mb-0 ">
            <div class="d-flex align-center" style="height: 20px">
              <div>
                {{ limitPlan.name }}
              </div>
              <div
                  v-if="preSelectedLimitPlan.id === limitPlan.id"
                  class="pl-2 edit-limitation-btn"
              >
                <v-btn
                    class="plain-button"
                    id="edit-plan-limit-btn"
                    color="primary"
                    icon
                    x-small
                    plain
                    @click.prevent.stop="showProductLimitDialog"
                >
                  <app-svg
                      size="12"
                      src="~/assets/img/icons/edit.svg"
                  />
                </v-btn>
              </div>
            </div>

            <div class="text--secondary text-small pt-2">
              {{ limitPlan.description }}

              <div v-if="preSelectedLimitPlan.id === limitPlan.id">
                Current limit is {{ organization.project_limit }} project
              </div>
            </div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>

    <organization-tab-project-limitation-edit-dialog
        v-if="isProjectLimitationDialogVisible"
        v-model="isProjectLimitationDialogVisible"
        :key="projectLimitationDialogKey"
        :limit-plan-prop="preSelectedLimitPlan"
    />

    <organization-tab-project-limitation-dialog-confirm-plan-change
        v-if="isDialogConfirmPlanChangeVisible"
        v-model="isDialogConfirmPlanChangeVisible"
        :key="keyDialogConfirmPlanChange"
        :project-limitation-prop="preSelectedLimitPlan"
        @noPlanChange="noPlanChange"
        @setPreSelectedLimitPlan="setPreSelectedLimitPlan"
    />

  </div>
</template>


<script>
import AppSvg from '@/components/app/AppSvg.vue'
import OrganizationTabProjectLimitationEditDialog
  from '@/components/organization/tab/project-limitations/dialog/OrganizationTabProjectLimitationEditDialog.vue'
import OrganizationTabProjectLimitationDialogConfirmPlanChange
  from '@/components/organization/tab/project-limitations/dialog/OrganizationTabProjectLimitationDialogConfirmPlanChange.vue'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/organization'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'OrganizationTabProjectLimitationsContent',
  components: {
    AppSvg,
    OrganizationTabProjectLimitationEditDialog,
    OrganizationTabProjectLimitationDialogConfirmPlanChange
  },

  data () {
    return {
      preSelectedLimitPlan: null,

      limitPlans: [
        {
          id: uuidv4(),
          name: 'Basic Plan',
          description: 'Users can be limited from 1 to 9 projects, default is 1',
          limit: [1, 9]
        },
        {
          id: uuidv4(),
          name: 'Standard Plan',
          description: 'Users can be limited from 10 to 99 projects, default is 10',
          limit: [10, 99]
        },
        {
          id: uuidv4(),
          name: 'Enterprise Unlimited',
          description: 'Users can be limited from 100 to 1000 projects, default is 100',
          limit: [100, 10000]
        }
      ],

      isProjectLimitationDialogVisible: false,
      projectLimitationDialogKey: uuidv4(),

      isDialogConfirmPlanChangeVisible: false,
      keyDialogConfirmPlanChange: uuidv4(),

      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters({
      organization: 'organization/getOrganization',
      organizationCurrentState: 'organization/getCurrentState'
    })
  },

  methods: {
    showProjectLimitPlanConformationDialog () {
      this.isDialogConfirmPlanChangeVisible = true
      this.keyDialogConfirmPlanChange = uuidv4()
    },

    noPlanChange () {
      this.setPreSelectedLimitPlan()
    },


    showProductLimitDialog () {
      this.isProjectLimitationDialogVisible = true
      this.projectLimitationDialogKey = uuidv4()
    },

    setPreSelectedLimitPlan () {
      for (const eachLimitPlan of this.limitPlans) {
        if (this.organization.project_limit >= eachLimitPlan.limit[0]
            && this.organization.project_limit <= eachLimitPlan.limit[1]) {
          this.preSelectedLimitPlan = eachLimitPlan
        }
      }
    }
  },

  created () {
    this.setPreSelectedLimitPlan()
  }
}
</script>


<style scoped lang="scss">
.edit-limitation-btn {
  display: none;
}

.limitation-line:hover {
  background-color: var(--v-grey-lighten2);

  .edit-limitation-btn {
    display: block;
  }
}
</style>