export const releaseMixin  = {
  methods: {
    fileName (storageLocation) {
      const splited = storageLocation.split('/')
      return splited[splited.length-1]
    },

    fileNameWin (release) {
      return this.fileName(release.windows_file_id.storage_location)
    },

    fileNameMac (release) {
      return this.fileName(release.macos_file_id.storage_location)
    }
  }
}