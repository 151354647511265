import $axios from '../plugins/axios'

class AuthService {
  login (user) {
    return $axios.post(`/login`, { email: user.email, password: user.password })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

}

export default new AuthService()
