import { render, staticRenderFns } from "./OrganizationTabProjectLimitationEditDialog.vue?vue&type=template&id=0e6b6c92&scoped=true&"
import script from "./OrganizationTabProjectLimitationEditDialog.vue?vue&type=script&lang=js&"
export * from "./OrganizationTabProjectLimitationEditDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e6b6c92",
  null
  
)

export default component.exports