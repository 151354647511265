import { BaseClass } from '@/classes/base/BaseClass'

export class ProductClass extends BaseClass {
  constructor (data = {}) {
    super()
    this.api_domain = data.api_domain
    this.created_at = data.created_at
    this.domain =  data.domain
    this.id = data.id
    this.logo = data.logo
    this.name = data.name
    this.status = data.status
    this.updated_at = data.updated_at
    this.is_launcher = data.is_launcher
  }

  get image () {
    return this.logo
  }

}
