<template>
  <div>
    <h1 class="mb-6">
      Products
    </h1>

    <v-divider class="mt-1 mb-8" />

    <product-list-overview-container />
  </div>
</template>

<script>
import ProductListOverviewContainer from '@/components/product/ProductListOverviewContainer'

export default {
  name: 'HomeView',
  components: {
    ProductListOverviewContainer
  }
}
</script>
