<template>
  <div>
    <product-edit-left-side-panel
      v-if="product"
      :product-prop="product"
      @showDeleteDialog="showDeleteDialog"
      @showEditNameDialog="showEditNameDialog"
      @showEditImageDialog="showEditImageDialog"
    />

<!--    <product-dialog-delete-product-->
<!--      v-if="isDeleteDialogVisible"-->
<!--      v-model="isDeleteDialogVisible"-->
<!--      :key="deleteDialogKey + '-product-delete-dialog'"-->
<!--    />-->

<!--    <product-dialog-edit-name-->
<!--      v-if="isEditNameDialogVisible"-->
<!--      :key="editNameDialogKey + '-product-dialog-edit-name'"-->
<!--      v-model="isEditNameDialogVisible"-->
<!--      :product-name-prop="product.name"-->
<!--    />-->

<!--    <app-image-dialog-edit-->
<!--      v-if="isEditImageDialogVisible"-->
<!--      :key="updateImageDialogKey + '-change-image-dialog'"-->
<!--      v-model="isEditImageDialogVisible"-->
<!--      :image-prop.sync="product.image"-->
<!--      :is-error-occurred-while-uploading-prop.sync="isErrorOccurredWhileUploading"-->
<!--      :is-loading-prop="false"-->
<!--      :validation-error-message-prop="validationErrorMessage"-->
<!--      @changeImage="changeProductImage"-->
<!--    />-->

  </div>
</template>

<script>
import ProductEditLeftSidePanel from '@/components/product/edit/ProductEditLeftSidePanel'
// import ProductDialogDeleteProduct from '@/components/product/dialog/ProductDialogDeleteProduct'
// import ProductDialogEditName from '@/components/product/dialog/ProductDialogEditName'
// import AppImageDialogEdit from '@/components/app/image/AppImageDialogEdit'

import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/product'

export default {
  name: "ProductEditLeftSidePanelContainer",

  components: {
    ProductEditLeftSidePanel,
    // ProductDialogDeleteProduct,
    // ProductDialogEditName,
    // AppImageDialogEdit
  },

  data () {
    return {

      isDeleteDialogVisible: false,
      deleteDialogKey: 0,

      isEditNameDialogVisible: false,
      editNameDialogKey: 0,

      isEditImageDialogVisible: false,
      updateImageDialogKey: 0,

      isErrorOccurredWhileUploading: false,
      validationErrorMessage: '',

      actionStateConstants: actionStates,

    }
  },

  computed: {
    ...mapGetters(
      {
        product: 'product/getProduct',
        currentState: 'product/getCurrentState'
      }
    )
  },

  methods: {
    showDeleteDialog () {
      this.isDeleteDialogVisible = true
      this.deleteDialogKey++
    },

    showEditNameDialog () {
      this.isEditNameDialogVisible = true
      this.editNameDialogKey++
    },

    showEditImageDialog () {
      this.isEditImageDialogVisible = true
      this.updateImageDialogKey++
    },

    changeProductImage () {
      console.log('upload product image')
    }

  }
}
</script>

<style scoped>

</style>