<template>
  <div>
    <product-list-overview
      :is-loading-prop="isLoading"
      :products-prop="normalProducts"
      :can-create-prop="true"
      @openCreateProductDialog="openCreateProductDialog"
    />


    <div class="d-flex align-center mt-8 mb-8">
      <div class="text-small">
        Launcher
      </div>
      <v-divider class="ml-3" />
    </div>

    <product-list-overview
        :is-loading-prop="isLoading"
        :products-prop="launchers"
        :can-create-prop="false"
    />

    <product-dialog-create-edit
      :key="createProductDialogKey"
      v-model="isDialogVisible"
      :title="'Create Product'"
      :products-prop="allProducts"
      @addProduct="addProductOptimisticUpdate"
    />
  </div>
</template>

<script>
import ProductListOverview from '@/components/product/ProductListOverview'
import ProductService from '@/services/product/products.service'
import ProductDialogCreateEdit from '@/components/product/dialog/ProductDialogCreateEdit.vue'

export default {
  name: "ProductListOverviewContainer",
  components: { ProductListOverview, ProductDialogCreateEdit },

  data () {
    return {
      isDialogVisible: false,
      createProductDialogKey: 0,
      isLoading: false,
      allProducts: [],
      normalProducts: [],
      launchers: []
    }
  },

  methods: {
    async getProducts () {
      try {
        this.isLoading = true
        this.allProducts = await ProductService.getAllProducts()
        this.splitProducts()
      } finally {
        this.isLoading = false
      }
    },

    splitProducts () {
      this.$nextTick(() => {
        const onlyLaunchers = this.allProducts.filter(item => Boolean(item.is_launcher) === true)
        this.normalProducts = this.allProducts.filter(item => Boolean(item.is_launcher) === false)
        this.launchers = onlyLaunchers
      })

    },

    addProductOptimisticUpdate (product) {
      const beProduct = {
        id: product.id,
        name: product.name,
        slug: product.slug,
        domain: product.domain,
        api_domain: product.apiDomain,
        sqs_queue: product.sqs_queue,
        is_launcher: product.launcher
      }
      this.allProducts.push(beProduct)
      this.splitProducts()
    },

    openCreateProductDialog () {
      this.createProductDialogKey++
      this.isDialogVisible = true
    }
  },

  mounted () {
    this.getProducts()
  }
}
</script>

<style scoped>

</style>
