<template>
  <div>
    <app-single-item-edit-left-section
      v-if="productProp"
      :entity-item-prop="productProp"
      :entity-type-prop="'project'"
      @showEditDescriptionDialog="$emit('showEditDescriptionDialog')"
      @showEditImageDialog="$emit('showEditImageDialog')"
      @showEditNameDialog="$emit('showEditNameDialog')"
    >
<!--      <template #other-edit-fields>-->

<!--        <div class="pt-10">-->
<!--          <v-tooltip-->
<!--            bottom-->
<!--            color="primary"-->
<!--          >-->
<!--            <span>Remove</span>-->

<!--            <template #activator="{ on, attrs }">-->
<!--              <v-btn-->
<!--                class="delete-icon"-->
<!--                color="primary"-->
<!--                icon-->
<!--                x-small-->
<!--                v-bind="attrs"-->
<!--                @click="$emit('showDeleteDialog')"-->
<!--                v-on="on"-->
<!--              >-->
<!--                <app-svg-->
<!--                  size="24"-->
<!--                  src="~/assets/img/icons/delete.svg"-->
<!--                />-->
<!--              </v-btn>-->
<!--            </template>-->

<!--          </v-tooltip>-->
<!--        </div>-->

<!--      </template>-->
    </app-single-item-edit-left-section>
  </div>
</template>

<script>
// import AppSvg from '@/components/app/AppSvg'
import AppSingleItemEditLeftSection from '@/components/app/AppSingleItemEditLeftSection'

export default {
  name: "ProductEditLeftSidePanel",

  components: {
    // AppSvg,
    AppSingleItemEditLeftSection
  },

  props: {
    productProp: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>