import { required, helpers } from 'vuelidate/lib/validators'
export const versionMixin = {
  data () {
    return {
      versionNumber: null,
    }
  },

  props: {
    releasesProp: {
      type: Array,
      required: true
    }
  },

  validations: {
    versionNumber: {
      required,
      validChars: helpers.regex('valid chars', new RegExp(/^\d{2}\.\d{2}\.\d{4}$/))
    }
  },

  computed: {
    previousVersions () {
      return this.releasesProp.map(item => item.version)
    },

    isValidVersion () {
      if(this.versionNumber && this.releasesProp && this.releasesProp.length) {

        const highestVersionObject = this.releasesProp.reduce((prev, current) => {
          return (prev._versionAsInt > current._versionAsInt) ? prev : current
        })

        const newVersionAsInt = parseInt(this.versionNumber.replace(/\./g, ''))

        return newVersionAsInt > highestVersionObject._versionAsInt
      }
      return true
    },


    versionErrors () {
      const errors = []

      if (!this.$v.versionNumber.$dirty) {
        return errors
      }

      !this.$v.versionNumber.required && errors.push('You must add version number')
      !this.$v.versionNumber.validChars && errors.push(
        'Version must have the following format  xx.xx.xxxx and must be bigger than the previous version'
      )

      if (!this.isValidVersion) {
        errors.push('Version must be greater than the previous one')
      }

      return errors
    }
  },

  methods: {
    fileType (fileName) {
      const fileNameSplit = fileName.split('.')

      return fileNameSplit[fileNameSplit.length-1].toLowerCase()
    },

    customUrlEncodeFileName (str) {
      const specialChars = /[ !@#$%^&*()_+=[\]{};':"\\|,<>?]/g
      return str.replace(specialChars, '-').replace(/-+/g, '-')
    },
  }
}