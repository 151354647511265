import $axios from '@/plugins/axios'

class ProductReleasesConfiguratorService {

  getProductConfiguratorReleases (data) {
    return $axios.post('/get-configurator-releases', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  changeActiveStateProductConfRelease (data) {
    return $axios.post('/change-active-state-configurator-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  changeRequiredStateProductConfRelease (data) {
    return $axios.post('/change-required-state-configurator-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }


  createNewConfRelease (data) {
    return $axios.post('/create-new-configurator-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

  deleteConfRelease (data) {
    return $axios.post('/delete-configurator-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }
  updateConfRelease (data) {
    return $axios.post('/update-configurator-release', data)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error
      })
  }

}

export default new ProductReleasesConfiguratorService()
