<template>
  <app-dialog
      v-model="isDialogVisible"
      title="Ownership"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
  >

    <div>
      <v-text-field
          id="name-field"
          v-model="emailValue"
          :error-messages="emailErrors"
          placeholder="Email"
          clearable
          required
          @blur="$v.$touch()"
          @input="$v.emailValue.$touch()"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              :disabled="$v.$anyError || isNewFieldSameAsOldField"
              :loading="loading"
              @click="changeOwnerShip"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>


<script>
import AppDialog from '@/components/app/AppDialog'
import OrganizationsService from '@/services/organizations.service'

import { maxLength, required, helpers } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  name: 'OrganizationDialogChangeOwnership',

  mixins: [validationMixin],

  components: { AppDialog },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    ownerProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      emailValue: this.ownerProp.email,
      loading: false
    }
  },

  validations: {
    emailValue: {
      required,
      maxLength: maxLength(255),
      validChars: helpers.regex('valid chars',
          new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u, 'u')
      )
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isNewFieldSameAsOldField () {
      return this.emailValue && this.emailValue === this.ownerProp.email
    },

    emailErrors () {
      const errors = []
      if (!this.$v.emailValue.$dirty) {
        return errors
      }

      this.isNewFieldSameAsOldField && errors.push( ' email should be different than the current email')
      !this.$v.emailValue.maxLength && errors.push('Can contain maximum of 255 characters')
      !this.$v.emailValue.required && errors.push('Must contain at least 1 character')
      !this.$v.emailValue.validChars && errors.push('Must be a valid email')
      return errors
    }

  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async changeOwnerShip () {
      try {
        this.loading = true
        await OrganizationsService.changeOrganizationOwner({
          id: this.$route.params.id,
          ownerEmail: this.emailValue
        })
      } finally {
        this.loading = false
        this.$emit('fetchOrganization')
        this.hideDialog()
      }
    }
  }
}
</script>



<style scoped lang="scss">

</style>