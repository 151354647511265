import FileService from '@/services/file.service'
import { versionMixin } from '@/mixins/VersionMixin'
import { File } from '@/classes/product/releases/File'

import { v4 as uuidv4 } from 'uuid'
export class ProductReleasePluginCreator {
  constructor (data = {}) {
    this.year = data.year ? data.year : null
    this.file = data.file ? new File(data.file) : null
    this.fileId = data.fileId ? data.fileId : null
    this.preSignedUrl = null
    this.storageLocation = data.storageLocation ? data.storageLocation : null
    this.os = data.os

    this._yearErrors  = ''
  }


  async makePresignedUrl () {
    if (this.file) {
      this.fileId = uuidv4()
      const prom = await FileService.getPresignedUrl({
        id: this.fileId,
        filename: versionMixin.methods.customUrlEncodeFileName(this.file.name)
      })
      this.preSignedUrl = prom.url
    }
  }

  uploadFileToPresignedUrl () {
    if (this.preSignedUrl) {
      return FileService.uploadFileWithPresignedUrl({
        file: this.file,
        url: this.preSignedUrl,
      })
    }
  }

  checkFileNotValid () {
    this.checkYearValidity()


    if (!this.file || !this.file.name) return false

    const fileType = versionMixin.methods.fileType(this.file.name)

    if (this.os === 'win') {
      return !(fileType === 'exe' || fileType === 'msi')
    }
    return !(fileType === 'zip' || fileType === 'dmg')

  }

  isYearValid () {
    if (this.file) {
      const pattern = /^\d{4}$/
      if (!this.year || this.year.length === 0) return false
      return pattern.test(this.year)
    }
    return true
  }

  checkYearValidity () {
    if (this.isYearValid()) {
      this._yearErrors = ''
    } else {
      this._yearErrors = 'Year must be a positive 4 digit number'
    }
  }

  get validationErrMsg () {
   if (this.os === 'win') {
     return 'Only exe or msi formats are accepted'
   }

   return 'Only zip or dmg formats are accepted'
  }
}