import { BaseClass } from '@/classes/base/BaseClass'
import { ProductClass } from '@/classes/product/ProductClass'

export class OrganizationClass extends BaseClass {
  constructor (data = {}) {
    super()

    this.created_at = data.created_at
    this.domain = data.domain
    this.id = data.id
    this.logo = data.logo
    this.name = data.name
    this.owner = data.owner
    this.owner_id = data.owner_id
    this.products = ProductClass.createList(data.products)
    this.project_limit = data.project_limit
    this.slug = data.slug
    this.status = data.status
    this.updated_at = data.updated_at
    this.website = data.website
  }

  get image () {
    return this.logo
  }
}