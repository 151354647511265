<template>
  <app-dialog
      :value="isDialogVisible"
      v-bind="$attrs"
      v-on="$listeners"
      :title="title"
      max-width="500"
      :persistent="true"
  >

    <div>
      <v-text-field
          clearable
          required
          label="Version Number"
          v-model="versionNumber"
          :error-messages="versionErrors"
          class="padding-left"
          @blur="$v.$touch()"
          @input="$v.versionNumber.$touch()"
          :disabled="modeProp==='edit'"
      />

      <v-file-input
          v-model="windowsFile"
          placeholder="Upload file here"
          hint="Files must be only in zip or msi format ?"
          prepend-icon=""
          :clearable="false"
          messages="Files must be only in format .zip or .msi"
          class="padding-left"
          :error-messages="winFileErrors"
          @blur="checkWinFileTypeNotValid"
          @input="checkWinFileTypeNotValid"
      >
        <template #append>
          <div class="d-flex align-center justify-space-between">
            <v-chip color="var(--v-grey-lighten1)" text-color="white" x-small>
              Windows
            </v-chip>

            <app-svg
                size="16"
                src="~/assets/img/icons/mini-upload-file.svg"
                class="ml-12"
                color="var(--v-grey-darken1)"
            />
          </div>
        </template>

        <template #label v-if="modeProp === 'edit' && releaseProp && releaseProp.windows_file_id && !windowsFile">
          <div>
            {{ fileNameWin(releaseProp) }}
          </div>
        </template>
      </v-file-input>

      <div v-if="windowsUploadProgress" class="mt-2">
        <v-progress-linear
            v-model="windowsUploadProgress"
            color="primary"
            background-color="var(--v-grey-lighten2)"
            height="6"
            rounded
        >
        </v-progress-linear>

        <div class="mt-1 d-flex align-center justify-space-between">
          <div class="text-small text--secondary">
            The file is uploading <span class="pl-2">{{ Math.ceil(windowsUploadProgress) }}%</span>
          </div>

          <div>
            <v-btn class="text-decoration-underline" text plain @click="cancelWindowsUpload">
              <div class="text-small">
                Cancel
              </div>
            </v-btn>
          </div>
        </div>
      </div>


      <v-file-input
          placeholder="Upload file here"
          hint="Files must be only in zip or msi format ?"
          prepend-icon=""
          messages="Files must be only in format .zip or .msi"
          class="padding-left"
          v-model="macFile"
          :clearable="false"
          :error-messages="macFileErrors"
          @blur="checkMacFileTypeNotValid"
          @input="checkMacFileTypeNotValid"

      >
        <template #append>
          <div class="d-flex align-center justify-space-between">
            <v-chip color="var(--v-grey-lighten1)" text-color="white" x-small>
              Mac
            </v-chip>

            <app-svg
                size="16"
                src="~/assets/img/icons/mini-upload-file.svg"
                class="ml-12"
                color="var(--v-grey-darken1)"
            />
          </div>
        </template>

        <template #label v-if="modeProp === 'edit' && releaseProp && releaseProp.macos_file_id && !macFile">
          <div>
            {{ fileNameMac(releaseProp) }}
          </div>
        </template>
      </v-file-input>

      <div v-if="macUploadProgress" class="mt-2">
        <v-progress-linear
            v-model="macUploadProgress"
            color="primary"
            background-color="var(--v-grey-lighten2)"
            height="6"
            rounded
        >
        </v-progress-linear>

        <div class="mt-1 d-flex align-center justify-space-between">
          <div class="text-small text--secondary">
            The file is uploading <span class="pl-2">{{ Math.ceil(macUploadProgress) }}%</span>
          </div>

          <div>
            <v-btn class="text-decoration-underline" text plain @click="cancelMacUpload">
              <div class="text-small">
                Cancel
              </div>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="d-flex mt-6" v-if="modeProp !== 'edit'">
        <v-checkbox
            class="ma-0 pa-0"
            color="primary"
            @click.prevent=""
            :ripple="false"
            v-model="mandatory"
            :disabled="isCheckboxDisabled"
        />

        <div>
          Mandatory for users
        </div>
      </div>
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
              :disabled="loading"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              @click.native="createNewRelease"
              :disabled="isPublishBtnDisabled || loading"
              :loading="loading"
          >
            <span class="text-capitalize">
              Publish
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog.vue'
import AppSvg from '@/components/app/AppSvg.vue'

import FileService from '@/services/file.service'
import ProductReleasesConfiguratorService from '@/services/product/product.releases.configurator.service'

import { versionMixin } from '@/mixins/VersionMixin'
import { releaseMixin } from '@/mixins/ReleaseMixin'

import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

export default {
  name: 'ProductDialogAddEditConfiguratorRelease',
  mixins: [versionMixin, releaseMixin],

  components: {
    AppDialog,
    AppSvg
  },

  props: {
    value: {
      default: true
    },

    modeProp: {
      type: String,
      required: true
    },

    releaseProp: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  data () {
    return {
      versionNumber: this.modeProp === 'edit' ? this.releaseProp.version : null,
      windowsFile: null,
      windowsFileId: null,
      windowsUploadProgress: null,
      cancelWindowsTokenSource: null,
      cancelWindows: false,

      macFile: null,
      macFileId: null,
      macUploadProgress: null,
      cancelMacTokenSource: null,
      cancelMac: false,

      mandatory: false,
      loading: false,

      promises: {
        winUrlProm: this.releaseProp && this.releaseProp.windows_file_id ? this.releaseProp.windows_file_id.url : null,
        macUrlProm: this.releaseProp && this.releaseProp.macos_file_id ? this.releaseProp.macos_file_id.url : null,

        winFileProm: null,
        macFileProm: null
      },

      payloadCreate: {
        product_id: this.$route.params.id,
        mandatory: false,
        version: this.versionNumber,
        active: false
      },

      payloadEdit: {
        version: null
      }

    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isCheckboxDisabled () {
      return !this.windowsFile && !this.macFile
    },

    title () {
      return this.modeProp === 'add' ? 'Add New Release' : 'Edit Release'
    },

    isPublishBtnDisabled () {
      if (this.modeProp === 'edit') {
        return (!this.windowsFile && !this.macFile) || this.filesAreNotValid
      }
      return !this.versionNumber || this.$v.$anyError || !this.isValidVersion || this.filesAreNotValid
    },

    winFileErrors () {
      if (this.checkWinFileTypeNotValid()) {
        return 'Only .zip or .msi format is accepted!'
      }
      return ''
    },

    macFileErrors () {
      if (this.checkMacFileTypeNotValid()) {
        return 'Only .zip or .msi format is accepted!'
      }
      return ''
    },

    filesAreNotValid () {
      return this.checkWinFileTypeNotValid() || this.checkMacFileTypeNotValid()
    }
  },

  watch: {
    mandatory (value) {
      this.payloadCreate.mandatory = value
    },

    versionNumber: {
      immediate: true,
      handler (value) {
        if (this.modeProp === 'edit') {
          this.payloadEdit.version = this.versionNumber
        } else {
          this.payloadCreate.version = value
        }
      }
    },

    windowsFile () {
      this.promises.winUrlProm = null
    },

    macFile () {
      this.promises.macUrlProm = null
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    checkWinFileTypeNotValid () {
      if (!this.windowsFile) return false

      const fileType = this.fileType(this.windowsFile.name)
      return !(fileType === 'zip' || fileType === 'msi')

    },

    checkMacFileTypeNotValid () {
      if (!this.macFile) return false

      const fileType = this.fileType(this.macFile.name)
      return !(fileType === 'zip' || fileType === 'msi')
    },

    cancelWindowsUpload () {
      if (this.cancelWindowsTokenSource) {
        this.cancelWindowsTokenSource.cancel('Upload canceled by the user.')
        this.windowsFile = null
        this.windowsUploadProgress = null
        this.cancelWindows = true

        this.promises.winUrlProm = null
        this.promises.winFileProm = null

        delete this.payloadCreate.windows_file_id
      }
    },

    cancelMacUpload () {
      if (this.cancelMacTokenSource) {
        this.cancelMacTokenSource.cancel('Upload canceled by the user.')
        this.macFile = null
        this.macUploadProgress = null
        this.cancelMac = true

        this.promises.macUrlProm = null
        this.promises.macFileProm = null

        delete this.payloadCreate.macos_file_id

      }
    },

    onUploadProgressWindows (progress) {
      this.windowsUploadProgress = progress
    },

    onUploadProgressMac (progress) {
      this.macUploadProgress = progress
    },

    promiseWindowsUrl () {
      if (this.windowsFile && !this.promises.winUrlProm) {
        this.windowsFileId = uuidv4()
        this.promises.winUrlProm = FileService.getPresignedUrl({
          id: this.windowsFileId,
          filename: this.customUrlEncodeFileName(this.windowsFile.name)
        })
      }
      return this.promises.winUrlProm
    },

    promiseMacUrl () {
      if (this.macFile && !this.promises.macUrlProm) {
        this.macFileId = uuidv4()
        this.promises.macUrlProm = FileService.getPresignedUrl(
            {id: this.macFileId, filename: this.customUrlEncodeFileName(this.macFile.name)}
        )
      }
      return this.promises.macUrlProm
    },

    promiseUploadWinFile (windowsUrl) {
      if (windowsUrl && !this.promises.winFileProm && this.windowsFile) {
        this.promises.winFileProm =
            FileService.uploadFileWithPresignedUrl({
              file: this.windowsFile,
              url: windowsUrl.url,
              onProgress: this.onUploadProgressWindows,
              cancelToken: this.cancelWindowsTokenSource
            })

        if (this.modeProp === 'edit') {
          Object.assign(this.payloadEdit, {'release_id': this.releaseProp.id})
          Object.assign(this.payloadEdit, {'windows_file_id': this.windowsFileId})
        } else {
          Object.assign(this.payloadCreate, {'windows_file_id': this.windowsFileId})
        }

      }

      return this.promises.winFileProm
    },

    promiseUploadMacFile (macUrl) {
      if (macUrl && !this.promises.macFileProm && this.macFile) {
        this.promises.macFileProm =
            FileService.uploadFileWithPresignedUrl({
              file: this.macFile,
              url: macUrl.url,
              onProgress: this.onUploadProgressMac,
              cancelToken: this.cancelMacTokenSource

            })
        if (this.modeProp === 'edit') {
          Object.assign(this.payloadEdit, {'macos_file_id': this.macFileId})
        } else {
          Object.assign(this.payloadCreate, {'macos_file_id': this.macFileId})
        }

      }

      return this.promises.macFileProm
    },

    async createNewRelease () {
      this.loading = true
      this.cancelWindowsTokenSource = axios.CancelToken.source()
      this.cancelMacTokenSource = axios.CancelToken.source()

      try {
        const [windowsUrl, macUrl] = await Promise.all([this.promiseWindowsUrl(), this.promiseMacUrl()])

        await Promise.all([this.promiseUploadWinFile(windowsUrl), this.promiseUploadMacFile(macUrl)])

        if (!this.cancelWindows && !this.cancelMac) {
          if (this.modeProp === 'edit') {
            await ProductReleasesConfiguratorService.updateConfRelease(this.payloadEdit)
          } else {
            await ProductReleasesConfiguratorService.createNewConfRelease(this.payloadCreate)
          }

          this.$emit('fetchReleases')
          this.hideDialog()
        }

      } catch (e) {
        this.hideDialog()
        throw e
      } finally {
        this.loading = false
        this.cancelWindows = null
        this.cancelMac = null
      }
    }
  }
}
</script>

<style scoped>

</style>