<template>
  <div
    class="content-wrapper pa-4"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-layout class="flex-column flex-md-row">
      <div class="entity-details-section my-4 mx-auto mx-md-4">
        <v-skeleton-loader type="card" />

        <v-skeleton-loader class="mt-8" type="paragraph" />
      </div>

      <div class="flex-grow-1 ma-4">
        <v-skeleton-loader type="table" />
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "AppLoaderContent"
}
</script>
