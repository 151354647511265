<template>
  <div>
    <h1 class="mb-6">
      Organizations
    </h1>

    <v-divider class="mt-1 mb-8" />

    <organization-dialog-create-edit
      :key="createOrganizationDialogKey"
      v-model="isDialogVisible"
      :is-loading-prop="isLoading"
      :title="'Create Organization'"
      @createOrganization="createOrganization"
    />

    <list-overview
      :can-create=true
      :data-source.sync="organizations"
      :is-list-loading-prop="isLoadingOgr"
      :selectable="true"
      list-type="organizations"
      target-url="/organizations"
    >
      <template #create-card>
        <entity-card
          action="Create"
          entity-type="organization"
          @click.native="openCreateOrganizationDialog"
        />
      </template>

      <template #entity-card="{ entity, selectable, target }">
        <list-item-common
          :entity="entity"
          :selectable="selectable"
          :target="target"
        />
      </template>
    </list-overview>
  </div>
</template>

<script>
import OrganizationDialogCreateEdit from '@/components/organization/dialog/OrganizationDialogCreateEdit'
import OrganizationsService from '@/services/organizations.service'
import ListItemCommon from '@/components/list/ListItemCommon'
import ListOverview from '@/components/list/ListOverview'
import EntityCard from '@/components/entity/EntityCard'

export default {
  name: 'OrganizationsView',
  components: {
    OrganizationDialogCreateEdit,
    ListItemCommon,
    ListOverview,
    EntityCard
  },

  data () {
    return {
      isDialogVisible: false,
      createOrganizationDialogKey: 0,
      isLoading: false,
      isLoadingOgr: false,
      organizations: [],
    }
  },

  methods: {
    openCreateOrganizationDialog () {
      this.createOrganizationDialogKey++
      this.isDialogVisible = true
    },

    closeOrganizationNameModal () {
      this.isDialogVisible = false
    },

    async getOrganizations () {
      try {
        this.isLoadingOgr = true
        this.organizations = await OrganizationsService.getAllOrganizations()
      } finally {
        this.isLoadingOgr = false
      }

    },

    async createOrganization (organization) {
      this.isLoading = true

      try {
        await OrganizationsService.createOrganization(organization)
        await this.getOrganizations()
        this.closeOrganizationNameModal()
      } catch (error) {
        await this.$store.dispatch('snackbar/showSnackbarMessage', {
          message: 'Error create organization',
          duration: 4000,
          mode: 'fail'
        })
      } finally {
        this.isLoading = false
      }
    }
  },

  mounted () {
    this.getOrganizations()
  },
}
</script>
