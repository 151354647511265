import { BaseClass } from '@/classes/base/BaseClass'

export class ProductFeatureClass extends BaseClass {
  constructor (data = {}) {
    super()
    this.id = data.id
    this.enabled = data.enabled
    this.internal_id = data.internal_id
    this.name = data.name
    this.product_id = data.product_id
    this.created_at = data.created_at
    this.loadingEnableDisable = false
  }
  
}