<template>
  <div>
    <list-overview
      :can-create="canCreateProp"
      :data-source="productsProp"
      :is-list-loading-prop="isLoadingProp"
      :selectable="true"
      list-type="products"
      target-url="/products"
    >
      <template #create-card>
        <entity-card
          action="Create a new"
          entity-type="product"
          @click.native="$emit('openCreateProductDialog')"
        />
      </template>

      <template #entity-card="{ entity, selectable, target }">
        <list-item-common
          :entity="entity"
          :selectable="selectable"
          :target="target"
        />
      </template>
    </list-overview>
  </div>
</template>

<script>
import ListItemCommon from '@/components/list/ListItemCommon'
import ListOverview from '@/components/list/ListOverview'
import EntityCard from '@/components/entity/EntityCard.vue'

export default {
  name: "ProductListOverview",
  components: {
    ListItemCommon,
    ListOverview,
    EntityCard
  },

  props: {
    productsProp: {
      type: Array,
      required: true
    },

    isLoadingProp: {
      type: Boolean,
      required: true
    },

    canCreateProp: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
