<template>
  <app-dialog
      v-model="isDialogVisible"
      :title="title"
      max-width="480"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <div>
      <v-text-field
          id="name-field"
          v-model="productName"
          :error-messages="nameErrors"
          :placeholder="placeholderProp"
          clearable
          label="Name"
          required
          @blur="$v.productName.$touch()"
          @input="$v.productName.$touch()"
      />

      <v-text-field
          clearable
          required
          label="Slug"
          v-model="slug"
          :error-messages="filedErrors({
            fieldName: 'slug', fieldNameUi: 'Slug', maxLength: 30,
            beProp: 'slug', value: slug
            })"
          @blur="$v.slug.$touch()"
          @input="$v.slug.$touch()"
      />

      <v-text-field
          clearable
          required
          v-model="domain"
          label="Domain"
          :error-messages="filedErrors({
            fieldName: 'domain', fieldNameUi: 'Domain', maxLength: 255,
            beProp: 'domain', value: domain
            })"
          @blur="$v.domain.$touch()"
          @input="$v.domain.$touch()"
      />

      <v-text-field
          required
          clearable
          v-model="apiDomain"
          label="Api Domain"
          :error-messages="filedErrors({
            fieldName: 'apiDomain', fieldNameUi: 'Api Domain', maxLength: 255,
            beProp: 'api_domain', value: apiDomain
            })"
          @blur="$v.apiDomain.$touch()"
          @input="$v.apiDomain.$touch()"

      />

      <v-text-field
          required
          clearable
          v-model="sqsQueue"
          label="SQS Queue"
          :error-messages="filedErrors({
            fieldName: 'sqsQueue', fieldNameUi: 'SQS Queue', maxLength: 255,
            beProp: 'sqsQueue', value: sqsQueue
          })"
          @blur="$v.sqsQueue.$touch()"
          @input="$v.sqsQueue.$touch()"
      />

      <v-checkbox
          class="ma-0 pa-0"
          color="primary"
          @click.prevent=""
          :ripple="false"
          v-model="isLauncher"
          label="Launcher"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              :disabled="isCreateBtnDisabled"
              :loading="loading"
              block
              color="primary"
              raised
              rounded
              x-large
              @click.native="createClick"
          >
            <span class="text-capitalize">
              {{ action }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </app-dialog>
</template>

<script>

import AppDialog from '@/components/app/AppDialog'

import ProductsService from '@/services/product/products.service'

import { v4 as uuidv4 } from 'uuid'
import { maxLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'ProductDialogCreateEdit',
  components: {AppDialog},

  props: {
    title: {
      type: String,
      required: true
    },

    productsProp: {
      type: Array,
      required: true
    },

    placeholderProp: {
      type: String,
      default: 'Add product name...'
    },

    action: {
      type: String,
      default: 'Create'
    },

    value: {
      type: Boolean,
      default: false,
      required: true
    },

    productNameProp: {
      type: String,
      default: ''
    },

    slugProp: {
      type: String,
      default: ''
    },

    domainProp: {
      type: String,
      default: ''
    },

    apiDomainProp: {
      type: String,
      default: ''
    },

    sqsQueueProp: {
      type: String,
      default: ''
    },

    isLauncherProp: {
      type: Boolean,
      default: false
    }
  },

  validations: {
    productName: {
      required,
      maxLength: maxLength(30)
    },

    slug: {
      required,
      maxLength: maxLength(30)
    },

    domain: {
      required,
      maxLength: maxLength(255)
    },

    apiDomain: {
      required,
      maxLength: maxLength(255)
    },

    sqsQueue: {
      required,
      maxLength: maxLength(255)
    }

  },

  data () {
    return {
      productName: this.productNameProp,
      slug: this.slugProp,
      domain: this.domainProp,
      apiDomain: this.apiDomainProp,
      sqsQueue: this.sqsQueueProp,
      loading: false,
      isLauncher: this.isLauncherProp
    }
  },
  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    isNameChanged () {
      return this.productName === this.productNameProp
    },

    nameErrors () {
      const errors = []
      if (!this.$v.productName.$dirty) {
        return errors
      }

      this.isNameChanged && errors.push('Name should be different than the current name')
      !this.$v.productName.maxLength && errors.push('Name must be at most 30 characters long')
      !this.$v.productName.required && errors.push('Name is required.')
      return errors
    },

    isCreateBtnDisabled () {
      return this.$v.$anyError || this.isNameChanged || !this.slug.length || !this.domain.length ||
          !this.apiDomain.length || !this.sqsQueue.length
    }
  },

  methods: {
    hideDialog () {
      this.resetCatalogName()
      this.isDialogVisible = false
    },

    resetCatalogName () {
      this.productName = null
    },

    isUniquePropertyValues (property) {
      const uniquePropertyValues = ['domain', 'api_domain']
      return uniquePropertyValues.includes(property)
    },

    filedErrors ({fieldName, fieldNameUi, maxLength, beProp, value}) {
      const errors = []
      if (!this.$v[`${fieldName}`].$dirty) {
        return errors
      }

      !this.$v[`${fieldName}`].maxLength && errors.push(`${fieldNameUi} must be at most ${maxLength} characters long`)
      !this.$v[`${fieldName}`].required && errors.push(`${fieldNameUi} is required.`)


      if (this.isUniquePropertyValues(beProp)) {
        const uniqueValue = this.productsProp.find(item => item[`${beProp}`] === value)
        if (uniqueValue) {
          errors.push(`${fieldNameUi} is already used, please use different one.`)
        }
      }

      return errors
    },

    async createClick () {
      const payload = {
        id: uuidv4(),
        name: this.productName,
        slug: this.slug,
        domain: this.domain,
        api_domain: this.apiDomain,
        sqs_queue: this.sqsQueue,
        launcher: this.isLauncher
      }

      try {
        this.loading = true
        await ProductsService.createProduct(payload)
      } finally {
        this.loading = false
        this.$emit('addProduct', payload)
        this.hideDialog()
      }
    }
  }


}
</script>

<style scoped>

</style>
