<template>
  <div :class="colorClass" :style="stylesMap" class="next-svg" />
</template>

<script>
import { isCssColor } from 'vuetify/es5/util/colorUtils'

export default {
  props: {
    src: {
      type: String,
      required: true
    },
    // sets both width and height of the icon
    size: {
      type: [String, Number],
      default: ''
    },
    // sets the width of the icon (ignored if `size` is set)
    width: {
      type: [String, Number],
      default: 16
    },
    // sets the height of the icon (ignored if `size` is set)
    height: {
      type: [String, Number],
      default: 16
    },
    // sets the color of the icon
    // this could be either a css color string (hex, rgba, etc.)
    // or a vuetify's like color format, eg. "primary lighten-2"
    color: {
      type: String,
      default: ''
    }
  },

  computed: {
    cleanSrc () {
      // clean up the alias prefix and file extension so that it can dynamically
      // passed to the webpack require loader function
      return this.src.replace(/^(~|@)\/assets\//, '').replace(/\.svg$/, '')
    },

    stylesMap () {
      const result = {
        width: (this.size || this.width) + 'px',
        height: (this.size || this.height) + 'px',
        mask:
          'url(' +
          require('@/assets/' + this.cleanSrc + '.svg') +
          ') no-repeat center / contain',
        '-webkit-mask':
          'url(' +
          require('@/assets/' + this.cleanSrc + '.svg') +
          ') no-repeat center / contain'
      }

      if (isCssColor(this.color)) {
        result['background-color'] = this.color
      }

      return result
    },

    colorClass () {
      if (this.color && !isCssColor(this.color)) {
        return this.color
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.next-svg {
  background-color: currentColor; // default
  transition: transform 0.1s;

  &.rotate-90 {
    transform: rotate(-90deg);
  }

  &.rotate-180 {
    transform: rotate(-180deg);
  }

  &.rotate-270 {
    transform: rotate(-270deg);
  }
}
</style>
