<template>
  <div>
    <div
      class="big-row-btn-with-icon d-flex align-center pa-5 my-5"
      @click="addNewReleaseDialog('Add New Release')"
      >
        <v-avatar size="60">
          <div class="icon">
            <app-svg
                alt="icon"
                color="white"
                size="32"
                src="~/assets/img/icons/plus.svg"
            />
          </div>
        </v-avatar>

        <div class="section-title ml-5">
          Add new release
        </div>
      </div>

    <app-loader-listing v-if="loadingProp" />

<!--    TABLE CONTENT-->
    <div v-else>
      <div v-if="releasesProp.length">
        <v-container class="pa-0">
          <v-row
              class="text-start header-wrapper text--secondary text-small pl-4 pt-0 pb-0"
          >
            <v-col
                cols="3"
                class="text-wrap"
            >
              Game version
            </v-col>

            <v-col cols="2">
              Version
            </v-col>

            <v-col cols="1">
              Active
            </v-col>

            <v-col cols="1">
              Required
            </v-col>

            <v-col cols="2">
              Uploaded date
            </v-col>

            <v-col cols="3">
            </v-col>
          </v-row>

          <v-row class="text-start pl-5" v-for="release in releasesProp" :key="release.id">
            <v-col cols="3" class="text-wrap">
              <div class="d-flex align-start justify-space-between">

                <div class="d-flex justify-space-between align-center">
                  <span v-show="release.hasWindows" class="">
                    <app-svg size="24"  src="~/assets/img/icons/windows-icon.svg" />
                  </span>

                  <span v-show="release.hasArchicadWindows" class="ml-2">
                    <app-svg size="24" src="~/assets/img/icons/windows-archicad-icon.svg"/>
                  </span>

                  <span v-show="release.hasRevitWindows" class="ml-2">
                    <app-svg size="24" src="~/assets/img/icons/windows-revit-icon.svg" />
                  </span>


                  <span v-show="release.hasArchicadMac || release.hasMac" class="ml-2">
                    <app-svg size="24" src="~/assets/img/icons/mac-icon.svg" />
                  </span>

                </div>

                <v-chip v-if="release.mandatory_users" color="primary" small> Mandatory</v-chip>
              </div>

            </v-col>

            <v-col cols="2">{{release.version}}</v-col>

            <v-col cols="1">
              <v-checkbox
                  v-model="release.active"
                  class="ma-0 pa-0"
                  color="primary"
                  :ripple="false"
                  :disabled="!release.hasAnyFile ||
                    hasSmallerVersionWithField({ newVersion:release, filed: 'active' })"
                  @change="decideIfToChangeReleaseActiveStatus(release)"
              />
            </v-col>

            <v-col cols="1">
              <div  >
                <v-checkbox
                    v-model="release.mandatory_users"
                    class="ma-0 pa-0 disable-color-error"
                    color="var(--v-error-base)"
                    :ripple="false"
                    :disabled="!release.hasAnyFile ||
                      hasSmallerVersionWithField({ newVersion:release, filed: 'mandatory_users' })"
                    @change="showRequiredDialog(release)"
                />
              </div>
            </v-col>

            <v-col cols="2">{{$timeToLocal(release.updated_at)}}</v-col>

            <v-col cols="3" class="d-flex justify-space-around align-start">
              <v-btn
                  class=""
                  color="primary"
                  rounded
                  fab
                  icon
                  x-small
                  @click="editReleaseDialog(release)"
              >
                <app-svg
                    size="16"
                    src="~/assets/img/icons/edit.svg"
                />

              </v-btn>
              <v-btn
                  class="primary--text"
                  height="30px"
                  rounded
                  text
                  :disabled="isRemoveDisabled(release)"
                  @click="showDeleteDialog(release)"
              >

<!--                :disabled="isRemoveDisabled(release)"-->
                  <app-svg
                      class="mr-2"
                      src="@/assets/img/icons/delete.svg"
                      width="16"
                  />
                  Remove
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div v-else class="text-center mt-12 mb-4">
        No releases, please add release
      </div>
    </div>

<!--    DIALOG-->
    <component
        :is="addEditDialogProp"
        v-if="isAddEditDialogVisible"
        v-model="isAddEditDialogVisible"
        :key="keyAddEditDialog"
        :releases-prop="releasesProp"
        :release-prop="currentRelease"
        :mode-prop="dialogMode"
        @fetchReleases="$emit('fetchReleases')"
    />


    <product-dialog-delete-release
      v-if="isDeleteDialogVisible"
      v-model="isDeleteDialogVisible"
      :key="keyDeleteDialog"
      :release-prop="currentRelease"
      :delete-action-prop="deleteActionProp"
      @fetchReleases="$emit('fetchReleases')"
    />
  </div>
</template>


<script>
import AppSvg from '@/components/app/AppSvg.vue'
import AppLoaderListing from '@/components/app/AppLoaderListing.vue'
import ProductDialogDeleteRelease from '@/components/product/dialog/releases/ProductDialogDeleteRelease.vue'

import { releaseMixin } from '@/mixins/ReleaseMixin'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ProductTabReleasesVersionsTable',
  components: { AppSvg, AppLoaderListing, ProductDialogDeleteRelease },
  mixins: [releaseMixin],

  props: {
    addEditDialogProp: {
      type: Object
    },

    releasesProp: {
      type: Array,
      required: true
    },

    loadingProp: {
      type: Boolean,
      required: true
    },

    deleteActionProp: {
      type: Function,
      default: () => {
        return null
      }
    }
  },

  data () {
    return {
      isAddEditDialogVisible: false,
      keyAddEditDialog: null,
      statusState: false,

      isDeleteDialogVisible: false,
      keyDeleteDialog: null,

      currentRelease: null,

      dialogMode: 'add'
    }
  },

  methods: {
    isRemoveDisabled (release) {

      const activeRelease = this.releasesProp.find(item => item.active)
      const mandatoryRelease = this.releasesProp.find(item => item.mandatory_users)

      if (
          release.active || release.mandatory_users
          || (activeRelease && activeRelease._versionAsInt > release._versionAsInt)
          || (mandatoryRelease && mandatoryRelease._versionAsInt > release._versionAsInt)
      )
      {
        return true
      }

      return false
    },

    addNewReleaseDialog () {
      this.currentRelease = null
      this.dialogMode = 'add'
      this.isAddEditDialogVisible = true
      this.keyAddEditDialog = uuidv4()
    },

    editReleaseDialog (release) {
      this.currentRelease = release
      this.dialogMode = 'edit'
      this.isAddEditDialogVisible = true
      this.keyAddEditDialog = uuidv4()
    },

    showRequiredDialog (release) {
      if (!release.mandatory_users) {
        this.$nextTick(() => {
          this.$set(release, 'mandatory_users', true)
        })

      } else {
        this.$emit('showRequiredDialog', release)
      }
    },

    decideIfToChangeReleaseActiveStatus (release) {
      if (!release.active) {
        this.$nextTick(() => {
          this.$set(release, 'active', true)
        })
      } else {
        this.$emit('changeStatus', { releaseId: release.id, state: release.active })
      }
    },

    showDeleteDialog (release) {
      this.isDeleteDialogVisible = true
      this.keyDeleteDialog = uuidv4()
      this.currentRelease = release
    },

    hasSmallerVersionWithField ({ newVersion, filed }) {
      const currentVersionWithField = this.releasesProp.find (item => item[`${filed}`])

      if (currentVersionWithField) {
        return newVersion._versionAsInt < currentVersionWithField._versionAsInt
      }

      return false
    }
  },
}
</script>

<style scoped lang="scss">

.header-wrapper {
  background-color: var(--v-grey-lighten4);
}

.text-wrap {
  overflow-wrap: break-word;
}

.max-width {
  max-width: 200px;
}


</style>