import { releaseMixin } from '@/mixins/ReleaseMixin'

export class File {
  constructor (data = {}) {
    this.created_at = data.created_at
    this.hash= data.hash
    this.id = data.id
    this.storage_location = data.storage_location
    this.updated_at = data.updated_at
    this.url = data.url
    this.name = releaseMixin.methods.fileName(this.storage_location)
  }
}