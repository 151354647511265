<template>
  <app-dialog
    v-model="isDialogVisible"
    :title="'Add new Features'"
    max-width="850"
    v-bind="$attrs"
    v-on="$listeners"
  >

    <app-loader-listing v-if="loadingAvailableOrganizations" />

    <div class="features-container pt-4" v-if="!loadingAvailableOrganizations && features.length">
          <div class="d-flex align-center pl-3">
              <v-checkbox
                :ripple="false"
                @change="bulkSelectItems"
                :indeterminate="selectedFeatures.length > 0 &&  selectedFeatures.length < features.length"
                :input-value="selectedFeatures.length > 0 &&  selectedFeatures.length === features.length"
                id="select-all-checkbox"
              />
              <strong>Select All</strong>
          </div>

      <div v-for="feature in features" :key="feature.id">
        <v-hover v-slot="{ hover }">
          <template>
            <div class="d-flex align-center pl-3" :class="hover && 'hovered'">
            <v-checkbox
              :ripple="false"
              id="feature-checkbox"
              @change="onItemToggle($event,feature)"
              :input-value="selectedFeatures.includes(feature)"
            />
            <div class="pl-2">
              {{ feature.name }}
            </div>
          </div>
          </template>
        </v-hover>
      </div>

    </div>

    <div v-if="!loadingAvailableOrganizations && !features.length">
      Currently three are no features to add!
    </div>

    <template #actions>
      <v-row class="d-flex justify-end">
        <v-col cols="3">
          <v-btn
            id="close-dialog-button"
            block
            color="primary"
            outlined
            raised
            rounded
            x-large
            @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="3">
          <v-btn
            id="submit-button"
            block
            color="primary"
            raised
            rounded
            x-large
            @click.native="addNewFeatures"
            :loading="loadingAddNewFeatures"
            :disabled="loadingAvailableOrganizations || !selectedFeatures.length || loadingAddNewFeatures"
          >
            Add features
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>

<script>
import AppDialog from '@/components/app/AppDialog'
import AppLoaderListing from '@/components/app/AppLoaderListing'

import OrganizationsService from '@/services/organizations.service'

export default {
  name: "OrganizationDialogAddNewFeature",
  components: {
    AppDialog,
    AppLoaderListing
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    productProp: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      features: [],
      loadingAvailableOrganizations: false,
      selectedFeatures: [],
      loadingAddNewFeatures: false,
    }
  },

  computed: {
    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async addNewFeatures () {
      try {
        this.loadingAddNewFeatures = true
        const payload = {
          'feature_ids': this.selectedFeatures.map(feature => feature.id),
          'organization_id': this.$route.params.id
        }
        await OrganizationsService.selectOrganizationProductFeature(payload)
      } catch (e) {
        await this.$error(e)
      } finally {
        this.loadingAddNewFeatures = false
        this.$emit('fetchOrganizationSelectedProductFeatures')
        this.hideDialog()
      }
    },

    onItemToggle (event, feature) {
      if (event && !this.selectedFeatures.includes(feature)) {
        this.selectedFeatures.push(feature)
      } else if ( !event  && this.selectedFeatures.includes(feature)) {
        this.selectedFeatures.splice(this.selectedFeatures.indexOf(feature), 1)
      }
    },

    bulkSelectItems (event) {
      if (event) {
        for (const feature of this.features) {
          if (!this.selectedFeatures.includes(feature)) {
            this.selectedFeatures.push(feature)
          }
        }
      } else {
        this.selectedFeatures = []
      }
    },

    async fetchOrganizationAvailableProductFeatures () {
      this.loadingAvailableOrganizations = true
      try {
        this.features =  await OrganizationsService.getOrganizationAvailableProductFeatures({
          organizationId: this.$route.params.id,
          productId: this.productProp.id
        })
      } catch (e) {
        await this.$error(e)
        this.$router.replace('/organizations')
      } finally {
        this.loadingAvailableOrganizations = false
      }
    }
  },

  async created () {
    await this.fetchOrganizationAvailableProductFeatures()
  },

  destroyed () {
    this.selectedFeatures = []
  }
}
</script>

<style scoped lang="scss">
.features-container {
  background-color: var(--v-grey-lighten3);
}

.hovered {
  background-color: var(--v-grey-lighten2);
}

</style>


