<template>
  <app-dialog
      v-model="isDialogVisible"
      title="Project Limit"
      max-width="500"
      v-bind="$attrs"
      v-on="$listeners"
      v-if="organization"
  >

    <div>
      <v-text-field
          id="project-limit"
          v-model="projectLimit"
          :error-messages="projectLimitErrors"
          @blur="$v.$touch()"
          @input="$v.projectLimit.$touch()"
          clearable
          required
          type="number"
      />
    </div>

    <template #actions>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
              id="close-dialog-button"
              block
              color="primary"
              outlined
              raised
              rounded
              x-large
              @click.prevent="hideDialog"
          >
            Cancel
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
              id="submit-button"
              block
              color="primary"
              raised
              rounded
              x-large
              @click="confirmClick"
              :loading="loading"
              :disabled="loading || $v.$anyError"
          >
            <span class="text-capitalize">
              Confirm
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

  </app-dialog>
</template>


<script>
import AppDialog from '@/components/app/AppDialog.vue'
import OrganizationsService from '@/services/organizations.service'

import { mapGetters } from 'vuex'
import { required, between, integer } from 'vuelidate/lib/validators'
export default {
  name: 'OrganizationTabProjectLimitationEditDialog',

  components: {
    AppDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    limitPlanProp: {
      type: Object,
      required: true
    }
  },

    data () {
    return {
      projectLimit: null,
      minProjectLimit: this.limitPlanProp.limit[0],
      maxProjectLimit: this.limitPlanProp.limit[1],
      loading: false
    }
  },

  validations () {
    return {
      projectLimit: {
        required,
        integer,
        betweenValue: between(this.minProjectLimit, this.maxProjectLimit)
      }
    }
  },

  computed: {
    ...mapGetters({
      organization: 'organization/getOrganization',
    }),

    isDialogVisible: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    projectLimitErrors () {
      const errors = []
      if (!this.$v.projectLimit.$dirty) {
        return errors
      }

      !this.$v.projectLimit.required && errors.push('Project Limit can not be empty')
      !this.$v.projectLimit.betweenValue && errors.push(
          `Project Limit must be a positive number between ${this.limitPlanProp.limit[0]} and ${this.limitPlanProp.limit[1]} `
      )
      !this.$v.projectLimit.integer && errors.push('Project Limit can be only a whole number')
      return errors
    }
  },

  methods: {
    hideDialog () {
      this.isDialogVisible = false
    },

    async confirmClick () {
      try {
        this.loading = true
        await OrganizationsService.changeOrganizationProjectLimit({
          id: this.organization.id,
          project_limit: this.projectLimit
        })

        await this.$store.dispatch(
            'organization/updateOrganizationProjectLimit',
            this.projectLimit
        )
      }
      finally {
        this.loading = false
        this.hideDialog()
      }
    }
  },

  mounted () {
    this.projectLimit = this.organization.project_limit
  }
}
</script>



<style scoped lang="scss">

</style>