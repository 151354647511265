<template>
  <div>

    <!--    PRODUCT TABS-->
    <v-tabs
        v-model="tab"
        class="pb-4"
        active-class="simple-tab-active"
        background-color="transparent"
        hide-slider
        show-arrows
        v-if="organization && organization.products && organization.products.length"
    >
      <v-tab
          v-for="product in organization.products"
          :key="product.id"
          class="simple-tab"
          :disabled="product.name === 'Office'"
      >
        <!--        Currently we are showing only Sandbox as a product, because we are not ready with the office product(enable disable)-->
        <div>
          {{ product.name  }}
        </div>

      </v-tab>
    </v-tabs>

    <v-divider/>

    <!--    EACH PRODUCT TAB CONTENT-->
    <div v-if="organization && organization.products && organization.products.length">
      <v-tabs-items
          v-model="tab"
          class="mt-4"
      >
        <v-tab-item

            v-for="product in organization.products"
            :key="product.id"
        >
          <organization-tab-project-limitations-content />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <!--    NO PRODUCTS ATTACHED-->
    <div class="mt-8" v-if="!organization || !organization.products || !organization.products.length">
      <div>
        Currently there are no products attached to this organization
      </div>
    </div>

  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import OrganizationTabProjectLimitationsContent
  from '@/components/organization/tab/project-limitations/OrganizationTabProjectLimitationsContent.vue'

export default {
  name: 'OrganizationTabProjectLimitations',
  components: {
    OrganizationTabProjectLimitationsContent
  },

  data () {
    return {
      tab: null,
    }
  },

  computed: {
    ...mapGetters(
        {
          organization: 'organization/getOrganization'
        }
    ),

    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>