<template>
  <div>
    <organization-edit-left-side-panel
      v-if="organization"
      :organization-prop="organization"
      @showEditImageDialog="showEditImageDialog"
      @showEditNameDialog="showEditNameDialog"
      @showEditWebsiteDialog="showEditWebsiteDialog"
      @showEditSlugDialog="showEditSlugDialog"
      @showEditEmailDialog="showEditEmailDialog"
      @showAttachProductDialog="showAttachProductDialog"
      @showConfirmChangeOwnershipDialog="showConfirmChangeOwnershipDialog"
    />


    <organization-dialog-change-ownership-confirm
      v-if="isOwnerConfirmChangeDialogVisible"
      v-model="isOwnerConfirmChangeDialogVisible"
      :key="keyOwnerConfirmChange"
      @showChangeOwnerShipDialog="showChangeOwnerShipDialog"
    />

    <organization-dialog-change-ownership
        v-if="isOwnerChangeDialogVisible"
        v-model="isOwnerChangeDialogVisible"
        :key="keyOwnerChange"
        :owner-prop="organization.owner"
        @fetchOrganization="fetchOrganization"
    />

<!--    <app-image-dialog-edit-->
<!--      v-if="isEditImageDialogVisible"-->
<!--      :key="updateImageDialogKey"-->
<!--      v-model="isEditImageDialogVisible"-->
<!--      :image-prop.sync="organization.image"-->
<!--      :is-error-occurred-while-uploading-prop.sync="isErrorOccurredWhileUploading"-->
<!--      :is-loading-prop="false"-->
<!--      :validation-error-message-prop="validationErrorMessage"-->
<!--      @changeImage="changeOrganizationImage"-->
<!--    />-->

<!--    <app-edit-text-field-dialog-->
<!--      v-model="isEditNameDialogVisible"-->
<!--      :key="editNameDialogKey"-->
<!--      :field-value-prop="organization.name"-->
<!--      field-name-prop="Organization Name"-->
<!--      event-name-prop="editOrganizationName"-->
<!--      title-name-prop="Edit Organization Name"-->
<!--    />-->

<!--    <app-edit-text-field-dialog-->
<!--      v-model="isEditWebsiteDialogVisible"-->
<!--      :key="editWebsiteDialogKey"-->
<!--      :field-value-prop="organization.website"-->
<!--      field-name-prop="Website Name"-->
<!--      event-name-prop="editWebsiteName"-->
<!--      title-name-prop="Edit Website Name"-->
<!--    />-->

<!--    <app-edit-text-field-dialog-->
<!--      v-model="isEditSlugDialogVisible"-->
<!--      :key="editSlugDialogKey"-->
<!--      :field-value-prop="organization.slug"-->
<!--      field-name-prop="Slug Name"-->
<!--      event-name-prop="editSlugName"-->
<!--      title-name-prop="Edit Slug Name"-->
<!--    />-->

<!--    <app-edit-text-field-dialog-->
<!--      v-model="isEditEmailDialogVisible"-->
<!--      :key="editEmailDialogKey"-->
<!--      :field-value-prop="organization.owner_email"-->
<!--      field-name-prop="Email Name"-->
<!--      event-name-prop="editEmailName"-->
<!--      title-name-prop="Edit Email Name"-->
<!--    />-->

<!--    <organization-dialog-attach-product-->
<!--      v-model="isAttachProductDialogVisible"-->
<!--      :key="attachProductDialogKey"-->
<!--      :products-prop="organization.products"-->
<!--    />-->

  </div>
</template>

<script>
import OrganizationEditLeftSidePanel from '@/components/organization/edit/OrganizationEditLeftSidePanel'
// import AppImageDialogEdit from '@/components/app/image/AppImageDialogEdit'
// import AppEditTextFieldDialog from '@/components/app/edit/AppEditTextFieldDialog'
// import OrganizationDialogAttachProduct from '@/components/organization/dialog/OrganizationDialogAttachProduct'
import OrganizationDialogChangeOwnership from '@/components/organization/dialog/OrganizationDialogChangeOwnership.vue'

import OrganizationDialogChangeOwnershipConfirm
  from '@/components/organization/dialog/OrganizationDialogChangeOwnershipConfirm.vue'
import { mapGetters } from 'vuex'
import { actionStates } from '@/store/modules/product'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: "OrganizationEditLeftSidePanelContainer",

  components: {
    OrganizationEditLeftSidePanel,
    // AppImageDialogEdit,
    // AppEditTextFieldDialog,
    // OrganizationDialogAttachProduct,
    OrganizationDialogChangeOwnershipConfirm,
    OrganizationDialogChangeOwnership
  },

  data () {
    return {

      isEditImageDialogVisible: false,
      updateImageDialogKey: uuidv4(),

      isErrorOccurredWhileUploading: false,
      validationErrorMessage: '',

      isEditNameDialogVisible: false,
      editNameDialogKey: uuidv4(),

      isEditWebsiteDialogVisible: false,
      editWebsiteDialogKey: uuidv4(),

      isEditSlugDialogVisible: false,
      editSlugDialogKey: uuidv4(),

      isEditEmailDialogVisible: false,
      editEmailDialogKey: uuidv4(),

      isAttachProductDialogVisible: false,
      attachProductDialogKey: uuidv4(),

      isOwnerConfirmChangeDialogVisible: false,
      keyOwnerConfirmChange:  uuidv4(),

      isOwnerChangeDialogVisible: false,
      keyOwnerChange:  uuidv4(),

      actionStateConstants: actionStates
    }
  },

  computed: {
    ...mapGetters(
      {
        organization: 'organization/getOrganization',
        currentState: 'organization/getCurrentState'
      }
    )
  },

  methods: {
    showEditImageDialog () {
      this.isEditImageDialogVisible = true
      this.updateImageDialogKey = uuidv4()
    },

    showConfirmChangeOwnershipDialog () {
      this.isOwnerConfirmChangeDialogVisible = true
      this.keyOwnerConfirmChange = uuidv4()
    },

    showChangeOwnerShipDialog () {
      this.isOwnerChangeDialogVisible = true
      this.keyOwnerChange = uuidv4()
    },

    changeOrganizationImage () {
      console.log('change org image')
    },

    showEditNameDialog () {
      this.isEditNameDialogVisible = true
      this.editNameDialogKey = uuidv4()
    },

    showEditWebsiteDialog () {
      this.isEditWebsiteDialogVisible = true
      this.editWebsiteDialogKey = uuidv4()
    },

    showEditSlugDialog () {
      this.isEditSlugDialogVisible = true
      this.editSlugDialogKey = uuidv4()
    },

    showEditEmailDialog () {
      this.isEditEmailDialogVisible = true
      this.editEmailDialogKey = uuidv4()
    },

    showAttachProductDialog () {
      this.isAttachProductDialogVisible = true
      this.attachProductDialogKey = uuidv4()
    },

    async fetchOrganization () {
      await this.$store.dispatch('organization/fetchOrganization', this.$route.params.id)
    }


  },
}
</script>

<style scoped>

</style>