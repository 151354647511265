<template>
  <div class="content-wrapper pa-4">
    <v-layout class="flex-column flex-md-row">

      <product-edit-left-side-panel-container />

      <!--      TABS-->
      <div class="flex-grow-1 ma-4">
        <v-tabs
          v-model="selectedTab"
          active-class="tab-active"
          class="mb-4"
          hide-slider
          show-arrows
        >

          <v-tab
            key="features"
            href="#features"
            :disabled="Boolean(product.is_launcher)"
          >
            Features
          </v-tab>

          <v-tab
            key="releases"
            href="#releases"
          >
            Releases
          </v-tab>

<!--          <v-tab-->
<!--            key="legal-notice"-->
<!--            href="#legal-notice"-->
<!--          >-->
<!--            Legal Notice-->
<!--          </v-tab>-->

        </v-tabs>

        <div class="tabs-content-section pa-5">
          <v-tabs-items v-model="selectedTab">
            <v-tab-item
              key="releases"
              value="releases"
            >

              <product-tab-releases />
            </v-tab-item>

<!--            <v-tab-item-->
<!--              key="legal-notice"-->
<!--              value="legal-notice"-->
<!--            >-->
<!--              <product-tab-legal-notice />-->
<!--            </v-tab-item>-->

            <v-tab-item
              key="features"
              value="features"
            >
              <product-tab-features/>
            </v-tab-item>

          </v-tabs-items>
        </div>
      </div>
    </v-layout>
  </div>
</template>

<script>
import ProductEditLeftSidePanelContainer from '@/components/product/edit/ProductEditLeftSidePanelContainer'
import ProductTabReleases from '@/components/product/tab/ProductTabReleases'
// import ProductTabLegalNotice from '@/components/product/tab/ProductTabLegalNotice'
import ProductTabFeatures from '@/components/product/tab/ProductTabFeatures'

export default {
  name: 'ProductSingleOverview',

  components: {
    ProductEditLeftSidePanelContainer,
    ProductTabReleases,
    // ProductTabLegalNotice,
    ProductTabFeatures
  },


  computed: {
    selectedTab: {
      get () {
        return this.$route.query.tab
      },
      async set (tab) {
        await this.$router.push({
          query: { ...this.$route.query, tab }
        })
      }
    },

    product () {
      return this.$store.getters['product/getProduct']
    }
  }
}
</script>

<style scoped>

</style>