<template>
  <v-app-bar app clipped-left>
    <v-img
      id="home-logo-button"
      :aspect-ratio="3.25"
      :src="getLogoImage()"
      contain
      max-height="35"
      max-width="140"
      v-bind="$attrs"
      v-on="$listeners"
      @click.stop="$router.push(`/`)"
    />

    <v-spacer />

    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-tooltip
          bottom
          class="text-center"
          color="grey-darken1"
        >
          <span>Mihail Mihalev</span>

          <template #activator="data">
            <v-btn
              id="bar-account-button"
              class="mx-2"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar
                color="primary"
                size="28"
                v-bind="data.attrs"
                v-on="data.on"
              >
                  <span class="white--text">
                   MM
                  </span>
              </v-avatar>
            </v-btn>
          </template>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item id="menu-item-profile" link to="/profile">
          <v-list-item-title>My Account</v-list-item-title>
        </v-list-item>

        <v-list-item id="menu-item-sign-out" link @click.native="logout()">
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>

export default {
  name: 'TheDefaultLayoutWrapAppBar',

  methods: {
    getLogoImage () {
      return require('@/assets/img/bim-more-internal-logo.png')
    },

    async logout () {
      await this.$store.dispatch('auth/signOut')
      await this.$router.replace('/login')
    }
  }
}
</script>

<style scoped>

</style>
