import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AppLayoutContent from '@/AppLayoutContent'
import AuthLoginView from '@/views/auth/AuthLoginView'
import AuthForgottenPassword from '@/views/auth/AuthForgottenPassword'
import AppAuthLayoutContent from '@/AppAuthLayoutContent'
import OrganizationsView from '@/views/OrganizationsView'
import ProductSingleView from '@/views/product/ProductSingleView'
import OrganizationSingleView from '@/views/organization/OrganizationSingleView'

import guest from './middleware/guest'
import auth from './middleware/auth'

import middlewarePipeline from "./middlewarePipeline"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: AppLayoutContent,
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },

      {
        path: '/dashboard',
        component: HomeView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/products/:id',
        component: ProductSingleView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/organizations',
        name: 'organizations',
        component: OrganizationsView,
        meta: {
          middleware: [auth]
        }
      },

      {
        path: '/organizations/:id',
        component: OrganizationSingleView,
        meta: {
          middleware: [auth]
        }
      }
    ]
  },

  {
    path: '/login',
    component: AppAuthLayoutContent,
    children: [
      {
        path: '/login',
        component: AuthLoginView,
        meta: {
          middleware: [guest]
        }
      },

      {
        path: '/forgotten-password',
        component: AuthForgottenPassword,
        meta: {
          middleware: [guest]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = { from, next }

  return middleware[0]({ ...context, nextMiddleware: middlewarePipeline(context, middleware, 1) })
})

export default router
